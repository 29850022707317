import { ActionTree } from 'vuex';
import { PrescriptionState } from '@/store/Prescription/PrescriptionRoot';

// API
import BasicRequirement from '@/ship/Models/BasicRequirement';
import {
    getAllBasicRequirement,
    getAllTypesViolations,
    getCalls,
    getMainPackData,
    getViolationsAcceptance,
    getViolationStatuses,
    getViolationsWarranty,
} from '@/utils/api/Prescriptions';

import IncludeViolation from '@/ship/Models/IncludeViolation';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import IncludeCall from '@/ship/Models/IncludeCall';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import { keeper } from '@/plugins/PromiseKeeper/PromiseKeeper';

export const actions: ActionTree<PrescriptionState, PrescriptionState> = {
    async GET_ALL_REQUIREMENT({ state, commit }): Promise<BasicRequirement[]> {
        if (state.requirements) {
            return state.requirements;
        }

        const {
            data: { data: requirements },
        } = await getAllBasicRequirement();
        commit('SET_REQUIREMENT', requirements);

        return requirements;
    },

    async GET_ALL_VIOLATIONS({ commit }, params: { page: number; query: string }): Promise<IncludeViolation[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getAllTypesViolations(params.page, params.query);
        commit('GET_ALL_VIOLATIONS', violations);
        commit('PAGINATION', pagination);
        return violations;
    },

    async GET_ALL_VIOLATIONS_ACCEPTANCE(
        { commit },
        params: { page: number; query: string },
    ): Promise<IncludeViolationAcceptance[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getViolationsAcceptance(params.page, params.query);
        commit('GET_ALL_VIOLATIONS_ACCEPTANCE', violations);
        commit('PAGINATION', pagination);
        return violations;
    },

    async GET_ALL_VIOLATIONS_WARRANTY(
        { commit },
        params: { page: number; query: string },
    ): Promise<IncludeViolationWarranty[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getViolationsWarranty(params.page, params.query);
        const _violations = violations.map((item) => ({
            ...item,
            statusId: item.status,
        }));
        commit('GET_ALL_VIOLATIONS_WARRANTY', _violations);
        commit('PAGINATION', pagination);
        return _violations;
    },

    async GET_ALL_CALLS({ commit }, params: { page: number; query: string }): Promise<IncludeCall[]> {
        const {
            data: {
                data: calls,
                meta: { pagination: pagination },
            },
        } = await getCalls(params.page, params.query);
        commit('GET_ALL_CALLS', calls);
        commit('PAGINATION', pagination);
        return calls;
    },

    async GET_MAIN_PACK_DATA({ commit, state }, forceUpdate = false): Promise<MainPackData> {
        if (state.mainPackData && !forceUpdate) return state.mainPackData;

        return keeper.get('GET_MAIN_PACK_DATA', async () => {
            const { data: mainPackData } = await getMainPackData();

            commit('SET_MAIN_PACK_DATA', mainPackData);

            return mainPackData;
        });
    },

    async GET_VIOLATION_COUNT({ commit }, data: { cities: number[] }): Promise<ViolationCount> {
        const { data: violationCount } = await getViolationStatuses(data);
        commit('SET_VIOLATION_COUNT', violationCount);
        return violationCount;
    },
};
export default actions;
