export default {
    journal: {
        title: 'Журнал смет',

        byNumber: 'По номеру',
        byDate: 'По дате',

        name: 'Наименование',
        number: 'Номер',
        cost: 'Стоимость',
        estimator: 'Последнее изменение:\nсметчик',
        contractor: 'Последнее изменение:\nконтрагент',
        numberDays: 'Количество дней\nна согласовании',

        openEstimate: 'Открыть смету',
        notFound: 'По указанным параметрам фильтров документы не найдены',
    },

    listActs: {
        title: 'Реестр актов',
        sum: 'Итого по актам',
        count: 'Количество актов',
    },

    projects: {
        header: 'Мои проекты',
        code: 'Код',

        all: 'Все',
        active: 'Активные',
        completed: 'Завершенные',

        estimate: 'Сметы',
        additional: 'Доп.сметы',
        act: 'Акты',
        contract: 'Договора',
    },

    estimate: {
        title: 'Смета',
        positions: 'Позиции сметы',
        additionalEstimates: 'Доп.сметы',
        acts: 'Акты',

        expenses: 'Статьи затрат',
        expense: 'Статья\nзатрат',
        pp: 'п/п',
        naming: 'Наименование',
        currentNaming: 'Текущее наименование',
        level: 'Уровень',
        remains: 'Остаток',
        remainsInfo: 'Остаток кол-ва материалов/работ <br/> и суммы по смете',
        units: 'Ед.измерения',
        unitsShort: 'Ед.изм.',
        fullCost: 'Общая\nстоимость',
        expenditure: 'Расход\nна ед.',
        note: 'Примечание',
        characteristic: 'Характеристика',
        agreedCount: 'Согл.\nобъем',
        uploadInExcel: 'Выгрузить в Excel',
        createNewAct: 'Создать новый акт',
        createAdditionalEstimate: 'Создать доп.смету',
        downloadExcel: 'Действительно хотите скачать смету в формате Excel на свой компьютер?',

        totalCost: 'Итоговая стоимость работ и материалов с НДС',
        totalCostRemuneration: 'Всего по смете с учетом вознаграждения, включая НДС',
        withMaterials: 'В том числе материалы',
        withVAT: 'В том числе НДС',
        contractRemuneration: 'Вознаграждение по договору',
        open: 'Открыть смету',
        data: 'Данные сметы',
    },

    contract: {
        title: 'Договор',
        positions: 'Позиции договора',
        additionalEstimates: 'Доп.сметы',
        acts: 'Акты',

        expenses: 'Статьи затрат',
        expense: 'Статья затрат',
        pp: 'п/п',
        naming: 'Наименование',
        level: 'Уровень',
        remains: 'Остаток',
        remainsInfo: 'Остаток кол-ва материалов/работ <br/> и суммы по смете',
        units: 'Ед.измерения',
        unitsShort: 'Ед.изм.',
        fullCost: 'Общая\nстоимость',
        expenditure: 'Расход\nна ед.',
        note: 'Примечание',
        characteristic: 'Характеристика',
        uploadInExcel: 'Выгрузить в Excel',
        createNewAct: 'Создать новый акт',
        createAdditionalEstimate: 'Создать доп.смету',
        downloadExcel: 'Действительно хотите скачать договор в формате Excel на свой компьютер?',
        totalCost: 'Итоговая стоимость работ и материалов с НДС',
        totalCostRemuneration: 'Всего по смете с учетом вознаграждения, включая НДС',
        withMaterials: 'В том числе материалы',
        withVAT: 'В том числе НДС 20%',
        contractRemuneration: 'Вознаграждение по договору',
        open: 'Открыть договор',
        data: 'Данные договора',
    },

    additional: {
        title: 'Доп. смета',
        positions: 'Позиции доп.сметы',
        acts: 'Акты',
        uploadInExcel: 'Выгрузить в Excel',
        downloadTemplate: 'Выгрузить шаблон доп.сметы',
        addDataFromExcel: 'Загрузить из Excel',
        sync1c: 'Проверить в 1С',
        addDataHint: 'Очистить позиции и загрузить<br/>новые из файла Excel',
        remarks: 'Замечания',
        delete: 'Вы действительно хотите удалить доп.смету',
        downloadExcel: 'Действительно хотите скачать доп.смету в формате Excel на свой компьютер?',
        sureDownloadTemplate: 'Действительно хотите скачать шаблон доп.сметы на свой компьютер?',
        placeholder: 'Создайте доп.смету, чтобы начать ее заполнение <br/> для дальнейшей отправки на согласование',
        placeholderNoRights: 'У вас нет прав для создания доп.смет',
        placeholderExcel: 'Загрузите файл Excel, чтобы автоматически<br/> заполнить данные доп.сметы',
        send: 'Хотите отправить доп.смету',
        open: 'Открыть доп.смету',
        data: 'Данные доп.сметы',
        sendToSyncSuccess: 'Доп.смета успешно отправлена в 1с',
        sendToReviewSuccess: 'Доп.смета успешно отправлена на проверку',
        sendToRevisionSuccess: 'Доп.смета успешно возвращена на доработку',
        sendToCancellationSuccess: 'Доп.смета успешно отменена',
        sendToApprovalSuccess: 'Доп.смета успешно согласована',
    },

    act: {
        title: 'Акт',
        positions: 'Позиции акта',
        uploadInExcel: 'Выгрузить в Excel',
        downloadTemplate: 'Выгрузить шаблон акта',
        addDataFromExcel: 'Загрузить из Excel',
        sync1c: 'Проверить в 1С',
        addDataHint: 'Очистить позиции и загрузить<br/>новые из файла Excel',
        delete: 'Вы действительно хотите удалить акт',
        downloadExcel: 'Действительно хотите скачать акт в формате Excel на свой компьютер?',
        sureDownloadTemplate: 'Действительно хотите скачать шаблон акта на свой компьютер?',
        placeholder: 'Создайте акт, чтобы начать его заполнение <br/> для дальнейшей отправки на согласование',
        placeholderNoRights: 'У вас нет прав для создания актов',
        placeholderExcel: 'Загрузите файл Excel, чтобы автоматически<br/> заполнить данные акта',
        send: 'Хотите отправить акт',
        open: 'Открыть акт',
        data: 'Данные акта',
        sendToSyncSuccess: 'Акт успешно отправлен в 1с',
        sendToReviewSuccess: 'Акт успешно отправлен на проверку',
        sendToRevisionSuccess: 'Акт успешно возвращен на доработку',
        sendToCancellationSuccess: 'Акт успешно отменен',
        sendToApprovalSuccess: 'Акт успешно согласован',
    },

    excelTransferModal: {
        startTransferring: 'Начать перемещение',
        pleaseWaitTransferring: 'Пожалуйста, подождите, перемещение займет примерно 10 секунд',
        uploadExcelFromYourComputer:
            'Загрузите Excel с вашего компьютера для автоматического перемещения позиций в документ',
        fileIsUploading: 'Подождите, файл загружается',
        fileIsUploaded: 'Файл загружен, можете начать перемещение данных в документ',
        errorWhileUploading: 'Ошибка при загрузке',
        errorWhileTransferring: 'Ошибка при перемещении',
        excelUploading: 'Загрузка файла Excel',
        transferringSubtitle: 'После заполнения все данные из Excel перенесутся в таблицу доп.сметы',
        uploadingError: 'Не удалось загрузить файл. Пожалуйста, повторите попытку загрузки или загрузите новый файл',
        transferringError:
            'Произошла ошибка при перемещении данных из Excel. Повторите попытку, чтобы переместить данные из Excel',
        dataTransferred: 'Данные успешно перемещены',
        goToExtraEstimate: 'Перейти к документу',
    },

    generalPositionCatalogModal: {
        title: 'Общий каталог позиций',
        searchPlaceholder: 'Поиск по позициям',
        cancelChoise: 'Отменить выбор',
        addToExtraEstimate: 'Добавить в доп.смету',
        createNewPosition: 'Создать новую позицию',
        chooseItemToAddInExtraEstimate: 'Выберите пункт, чтобы добавить в доп.смету',
    },

    updateDataModal: {
        customerOrganization: 'Организация сметчика',
        documentNumber: 'Номер документа',
        generalContractorOrganizations: 'Организации контрагента',
        date: 'Дата составления',
        needToFillAllData: 'Необходимо заполнить все данные',
        fill: 'Заполните',
    },

    createNewPosition: {
        title: 'Создание новой позиции',
        naming: 'Наименование позиции',
    },

    documentType: {
        estimate: 'Основная смета',
        additional: 'Доп.смета',
        act: 'Акт',
        contract: 'Договор',
    },

    status: {
        draft: 'Черновик',
        canceled: 'Не согласован',
        checking: 'На проверке',
        fixing: 'На доработке',
        agreed: 'Согласован',
        agreedBySupervisor: 'Согласован ТН',
        agreedByProjectManager: 'Согласован РП',
    },

    projectStatus: {
        active: 'Активный',
        completed: 'Завершен',
    },

    part: {
        local: 'Локальная смета',
        chapter: 'Раздел',
        subchapter: 'Подраздел',
        job: 'Работа',
        material: 'Материал',
        LocalSmeta: 'Локальная смета',
        ChapterSmeta: 'Раздел',
        SubchapterSmeta: 'Подраздел',
        JobSmeta: 'Работа',
        MaterialSmeta: 'Материал',
    },

    sendFor: {
        review: 'Отправить на проверку',
        revision: 'Вернуть на доработку',
        cancellation: 'Отменить',
        approval: 'Согласовать',
    },

    action: {
        review: 'на проверку',
        revision: 'на доработку',
        cancellation: 'для отмены',
        approval: 'для согласования',
        sync: 'на проверку в 1С',
    },

    tooltip: {
        isOutOfSync: 'Отсутствует в справочнике 1C',
        isMismatched: 'Не соответствует сметной строке',
    },

    positionEdit: {
        title: 'Позиция сметы',
        action: 'Редактировать позицию',
    },

    history: 'Журнал изменений',
};
