import Organisation from '@/ship/Models/Organisation';
import Http from '@/ship/Http';
import { AxiosResponse } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import OrganisationEditRequest from '@/ship/Requests/OrganisationEditRequest';

export const addGeneralContractorToCustomer = async (
    generalContractorId: number,
    customerId: number,
): Promise<Organisation> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Organisation>>>(
        `/organisations/customer/general_contractor/add`,
        { customerId, generalContractorId },
    );

    return response.data.data;
};

export const deleteGeneralContractorToCustomer = async (
    generalContractorId: number,
    customerId: number,
): Promise<void> => {
    await Http.post<void, AxiosResponse>(
        `/organisations/customer/${customerId}/general_contractor/${generalContractorId}/delete`,
    );
};

export const addSubcontractorToGeneralContractor = async (
    generalContractorId: number,
    subcontractorId: number,
): Promise<Organisation> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Organisation>>>(
        `/organisations/general_contractor/subcontractor/add`,
        { generalContractorId, subcontractorId },
    );

    return response.data.data;
};

export const deleteSubcontractorToGeneralContractor = async (
    generalContractorId: number,
    subcontractorId: number,
): Promise<void> => {
    await Http.post<void, AxiosResponse>(
        `/organisations/general_contractor/${generalContractorId}/subcontractor/${subcontractorId}/delete`,
    );
};

/**
 * Binds new users to organisation
 * @param organisationId Updating organisation id (null for deleting)
 * @param users List of user ids
 */
export const changeUsersInOrganisation = (organisationId: number | null, users: number[]) => {
    return Http.post<ITransformedValue<Organisation>>(`/organisations/users/change`, { organisationId, users });
};

/**
 * Get only one organisation by id.
 * Permission: search-organisations
 *
 * @param id Organisation id
 * @param params Optional get request parametrs
 */
export const getAnOrganisation = (id: number, params?: GetRequestParams) => {
    return Http.get<ITransformedValue<Organisation>>(`/organisations/${id}?include=users`, { params });
};

export const getAllOrganisations = <D = Organisation>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/organisations?include=subcontractors`, { params });

export const createOrganisation = async (data: OrganisationEditRequest) => {
    const response = await Http.post<ITransformedValue<Organisation>>(`/organisations`, data);
    return response.data.data;
};

export const updateOrganisation = async (id: number, data: OrganisationEditRequest) => {
    const response = await Http.patch<ITransformedValue<Organisation>>(`/organisations/${id}`, {
        id,
        ...data,
    });

    return response.data.data;
};

export const checkUniqueOrganisationData = async (data: { INN?: number; contact_number?: string }) => {
    return (await Http.post<{ INN: boolean | null; phoneNumber: boolean | null }>('/organisations/check/unique/', data))
        .data;
};

export const findOrganisationByINN = async (inn: string): Promise<Organisation> => {
    const response = await Http.get<void, AxiosResponse<ITransformedValue<Organisation>>>(`/organisations/inn/${inn}`);

    return response.data.data;
};

export const getGeneralContractorOrganisationById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<Organisation>>> => {
    return Http.get(`/organisations/${id}?include=subcontractors,users`);
};
