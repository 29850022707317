import Floor from '@/ship/Models/Floor';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { AxiosResponse } from 'axios';

export const getFloor = <D = Floor>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`floors/${id}`, { params });

export const getAllFloors = <D = Floor>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/floors`, { params });

export const createFloor = (name: string, sectionId: number): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.post(`/floors`, { name, sectionId });
};

export const updateFloor = (
    id: number,
    name?: string | null,
    sectionId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.patch(`/floors/${id}`, { id, name, sectionId });
};

export const getFloorById = (id: number): Promise<AxiosResponse<ITransformedValue<Floor>>> => {
    return Http.get(`/floors/${id}?include=apartments`);
};
