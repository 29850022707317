export default {
    goToDrawings: 'Перейти к чертежам',
    goToModels: 'Перейти к моделям',
    uploadImage: 'Загрузить изображение',

    sidebar: {
        projects: 'Проекты',
        plans: 'Чертежи',
        tasks: 'Задачи',
        models: 'Модели',
    },

    sideForm: {
        tasks: 'Задачи',
        settings: 'Настройки',
        all: 'Все',
        inWork: 'В работе',
        onChecking: 'На проверке',
        forChecking: 'На проверку',
        waitingForChecking: 'Ожидает проверки',
        completed: 'Завершенные',
        completedTask: 'Завершена',
        overdue: 'Просроченные',
        accept: 'Принять',
        backInWork: 'Вернуть в работу',
        forceEnd: 'Завершить',
        perform: 'Выполнить',
        messages: 'Сообщения',
        edit: 'Редактировать',
        attach: 'Прикрепить файлы',
        prolongation: 'Продлить срок',
        goIn: 'Перейти в',
        newTask: 'Новая задача',
        deadlinePassed: 'Срок проверки чертежа истек',
        forceEndModalDescription: 'Вы уверены, что хотите принудительно завершить задачу?',
        forceEndModalTitle: 'Задача находится в работе',
        prolongationExecutorModalTitle: 'Запросить продление срока выполнения задачи',
        prolongationExecutorModalDescription: 'Если Вам нужно больше времени, попросите об этом автора задачи',
        prolongationExecutorModalReasons: 'Опишите причины...',
        prolongationExecutorModalDeadline: 'Новый срок выполнения:',
        prolongationAuthorModalTitle: 'Запрос на продление срока выполнения задачи',
        prolongationAuthorModalDescription:
            'NAME просит продлить срок выполнения задачи до <span>NEW_DEADLINE</span>. Текущий срок выполнения - OLD_DEADLINE.',
        prolongationAuthorModalComment: 'Комментарий пользователя:',
        prolongationAuthorModalCancel: 'Отклонить',
        prolongationAuthorModalDeadline: 'Указать свой срок',
        finish: 'Завершить',
        cancel: 'Отменить',
        request: 'Запросить',
        participants: 'нет участников | {n} участник | {n} участника | {n} участников',
        chat: 'Чат',
        from: 'от',
        to: 'Кому',
        creationDate: 'Дата создания',
        deadline: 'Срок выполнения',
        taskStatus: 'Статус задачи',
        taskDescription: 'Описание задачи',
        taskExecutors: 'Исполнители задачи',
        status: {
            overdue: 'Просрочена',
            inWork: 'В работе',
            onChecking: 'На проверке',
            completed: 'Завершена',
        },
        statusExecutor: {
            overdue: 'Просрочено',
            inWork: 'В работе',
            onCheck: 'На проверке',
            accepted: 'Завершено',
            production: 'На производстве',
            cancelled: 'Аннулирован',
            agreed: 'Согласовано',
            agreedFor: 'Согласовано за ',
            commentsFormed: 'Замечания сформированы',
            commentsFormedFor: 'Замечания сформированы за ',
        },
        actionExecutor: {
            accept: 'Принять',
            backToWork: 'Вернуть в работу',
            complete: 'Завершить',
            completeForced: 'Завершить принудительно',
        },
        substitute: 'замещает',
        substituting: 'Замещаемый',
    },

    stages: {
        MasterPlan: 'Мастер-план',
        PlanningSurveying: 'Проект планировки и межевания территории',
        Sketch: 'Эскизный проект',
        ProjectDocumentation: 'Проектная документация',
        WorkDocumentation: 'Рабочая документация',
        Landscaping: 'Проект ландшафтного дизайна и благоустройства',
        CommonAreasDesign: 'Дизайн-проект мест общего пользования',
        PreliminaryMaterials: 'Предварительные материалы',
        Research: 'Изыскания',
    },

    modal: {
        initPlansDescription: 'Выберите проект, организацию и исполнителя, а также назначьте срок сдачи',
        selectTerm: 'Выберите срок исполнения',
        selectProject: 'Выберете проект',
        selectDesigner: 'Выберете проектировщика',
        selectExecutor: 'Выберете исполнителя',
        planSetData: 'Заполните данные по чертежу',
        modelSetData: 'Заполните данные по модели',
        planSetDataForTask: 'Заполните данные по чертежу\u000Aдля создания задачи',
        modelSetDataForTask: 'Заполните данные по модели\u000Aдля создания задачи',
        uploadPlan: 'Загрузить чертеж',
        cancel: 'Отменить',
        abort: 'Отмена',
        apply: 'Применить',
        designer: 'Проектировщик',
        project: 'Проект',
        deadline: 'Срок исполнения',
        writeTaskName: 'Напишите название задачи',
        findTaskByName: 'Найти задачу по названию',
        remarksExecutors: 'Исполнители',
        planCheckDeadline: 'Срок проверки чертежа',
        modelCheckDeadline: 'Срок проверки модели',
        commentForExecutor: 'Комментарий для исполнителя',
        addOneMoreTask: 'Добавить еще одну задачу',
        sendTasksToWork: 'добавьте задачи | Отправить задачу в работу | Отправить задачи в работу',
        next: 'Далее',
        fillLater: 'Заполню позже',
        save: 'Сохранить',
        needToFillAllData: 'Необходимо заполнить все данные',
        fillAllDataForTask: 'Чтобы опубликовать задачу, необходимо заполнить данные',
        pleaseCheckData: 'Пожалуйста, проверьте данные',
        planCheckBeforeRevision: 'Проверьте данные перед отправкой чертежа на доработку в проектную организацию',
        modelCheckBeforeRevision: 'Проверьте данные перед отправкой модели на доработку в проектную организацию',
        planCheckBeforeBackToWork: 'Проверьте данные перед возвратом чертежа в работу',
        modelCheckBeforeBackToWork: 'Проверьте данные перед возвратом модели в работу',
        fileIsLoading: 'Подождите, файл загружается',
        fileLoadedAndReadyForSending: 'Файл загружен и может быть отправлен на проверку',
        uploadNewDocumentForChecking: 'Загрузите исправленный документ для отправки на проверку',
        sendModelForChecking: 'Отправьте модель на проверку',
        fileWillDuplicateAttention:
            'Внимание! При загрузке с компьютера файл будет доступен в качестве новой версии чертежа в T.Cloud',
        planCantSendForCheckingTitle: 'Вы не можете отправить чертеж на проверку',
        modelCantSendForCheckingTitle: 'Вы не можете отправить модель на проверку',
        cantSendForCheckingDescription: 'У каждого замечания должен быть статус “Завершено” или “Отклонено”',
        planCantSendForRevisionTitle: 'Вы не можете отправить чертеж на доработку',
        modelCantSendForRevisionTitle: 'Вы не можете отправить модель на доработку',
        cantSendForRevisionDescription: 'Необходимо заполнить замечания для отправки на доработку',
        tcloudCloseConfirmTitle: 'Внесенные Вами данные не будут сохранены.\nВы уверены?',
        selectFromTCloud: 'Выбрать документ в T.Cloud',
        sureToSendToProduction: 'Вы действительно хотите отправить чертеж «В\u00A0производство»?',
        sureToSendToProductionMultiple: 'Вы действительно хотите отправить чертежи «В\u00A0производство»?',
        planSureToReturnBackToWork: 'Вы действительно хотите вернуть чертеж в работу?',
        planSureToReturnBackToWorkMultiple: 'Вы действительно хотите вернуть чертежи в работу?',
        modelSureToReturnBackToWork: 'Вы действительно хотите вернуть модель в работу?',
        modelSureToReturnBackToWorkMultiple: 'Вы действительно хотите вернуть модели в работу?',
        backToWorkUpdateDeadline: 'Пожалуйста, актуализируйте срок устранения замечаний.',
        backToWorkDeadline: 'Срок устранения замечаний:',
        successfullySentToProduction: 'Вы успешно отправили чертеж «В\u00A0производство»',
        successfullySentToProductionMultiple: 'Вы успешно отправили чертежи «В\u00A0производство»',
        successfullyReturnedBackToWork: 'Вы успешно вернули чертеж в работу',
        successfullyReturnedBackToWorkMultiple: 'Вы успешно вернули чертежи в работу',
        newVersionFound:
            'В системе T.Cloud Была обнаружена более новая версия. Заменить текущую рабочую версию на последнюю?',
        sureToCancel: 'Вы действительно хотите аннулировать чертеж?',
        sureToCancelDescription:
            'Вы сможете вернуть его в работу в любой момент. Все задачи по чертежу будут принудительно завершены.',
        cancelAction: 'Аннулировать',
        planSureToRequestReturnToWork: 'Вы действительно хотите запросить возврат чертежа в работу?',
        planSureToRequestReturnToWorkDescription: 'Необходимо указать причину для возврата чертежа',
        modelSureToRequestReturnToWork: 'Вы действительно хотите запросить возврат модели в работу?',
        modelSureToRequestReturnToWorkDescription: 'Необходимо указать причину для возврата модели',
        describeReasons: 'Опишите причины...',
        successfullyCanceled: 'Вы успешно аннулировали чертеж',
        planSuccessfullyRequested: 'Вы успешно запросили возврат чертежа в работу',
        modelSuccessfullyRequested: 'Вы успешно запросили возврат модели в работу',
        planSuccessfullyRejectedRequest: 'Вы успешно отменили запрос возврата чертежа в работу',
        modelSuccessfullyRejectedRequest: 'Вы успешно отменили запрос возврата модели в работу',
        remarkRejectionModalTitle: 'Вы действительно хотите отклонить замечание?',
        remarkRejectionModalDescription: 'Для отклонения замечания необходимо указать причины отклонения.',
        remarkRejectionModalReasons: 'Опишите причины...',
        remarkRejectionResetModalTitle: 'Уверены, что хотите сбросить статус “Отклонено”?',
        remarkRejectionResetModalDescription: 'После сброса статуса “Отклонено” причина останется в чате замечания',
        remarkRejectionChangeModalTitle: 'Изменить причину отклонения',
        remarkRejectionChangeModalDescription: 'Внесите исправления в текст и нажмите “изменить”',
        remarkRejectionSendModalTitle: 'Все замечания по документу отклонены',
        remarkRejectionSuccessModalTitle: 'Документ успешно отправлен на проверку',
        planGoToList: 'Перейти к списку чертежей',
        planStay: 'Остаться в этом чертеже',
        modelGoToList: 'Перейти к списку моделей',
        modelStay: 'Остаться в этой модели',
    },

    table: {
        name: 'Наименование',
        ID: 'ID',
        status: 'Статус',
        project: 'Проект',
        customer: 'Куратор со стороны Заказчика',
        authors: 'Представители Заказчика',
        customerManager: 'Региональный руководитель со стороны Заказчика',
        projectOrganisation: 'Проектная организация',
        generalDesigners: 'Проектные организации',
        organisationRepresentative: 'Куратор со стороны Проектной организации',
        organisationExecutor: 'Представители Проектной организации',
        generalDesignerManager: 'Главный специалист со стороны Проектной организации',
        planStage: 'Стадия чертежа',
        modelStage: 'Стадия модели',
        fixingDeadline: 'Срок устранения замечаний',
        checkingDeadline: 'Срок проверки',
        downloadDate: 'Дата загрузки',
        author: 'Автор',
        acceptDate: 'Дата принятия',
        createTask: 'Создать задачу',
        download: 'Скачать',
        remove: 'Удалить',
        planOpen: 'Открыть чертеж',
        modelOpen: 'Открыть модель',
        planData: 'Данные чертежа',
        modelData: 'Данные модели',
        planTasks: 'Задачи чертежа',
        modelTasks: 'Задачи модели',
        changelog: 'Журнал изменений',
        sureToRemove: 'Уверены, что хотите удалить чертеж из списка?',
        cancel: 'Отменить',
        alert: 'Выберите чертеж',
        cancelSelection: 'Отменить выбор',
        remarksDeadline: 'Срок устранения замечаний',
        planCheckDeadline: 'Срок проверки чертежа',
        modelCheckDeadline: 'Срок проверки модели',
        backToWork: 'Вернуть в работу',
        toProduction: 'В производство',
        nullify: 'Аннулировать',
        planAdd: 'Добавить чертеж',
        modelAdd: 'Добавить модель',
        openInTCloud: 'Открыть в T.Cloud',
        cantDownload: 'Не все выбранные файлы поддерживают формат скачивания',
    },

    filter: {
        status: 'Статус',
        remarkAuthor: 'Представители Заказчика',
        organisationRepresentative: 'Кураторы Проектной организации',
        organisationExecutor: 'Представители Проектной организации',
        project: 'Проект',
        customerPresenter: 'Кураторы Заказчика',
        addPlan: 'Добавить чертеж',
        addModel: 'Добавить модель',
        executor: 'Исполнители задачи',
        author: 'Авторы задачи',
        createdDateFrom: 'Дата создания от',
        createdDateTo: 'Дата создания до',
        deadlineFrom: 'Срок выполнения от',
        deadlineTo: 'Срок выполнения до',
        taskStatus: 'Статус задачи',
        executorStatus: 'Статус исполнителя в задаче',
        customerManager: 'Региональный руководитель Заказчика',
        generalDesignerManager: 'Главный специалист Проектной организации',
    },

    messages: {
        noComments: 'Нет комментариев',
        newComments: 'Нет комментариев | +{n} комментарий | +{n} комментария | +{n} комментариев',
    },

    status: {
        new: 'Новый',
        checking: 'На проверке',
        fixing: 'На доработке',
        adopted: 'Принят',
        inProduction: 'В\u00A0производстве',
        cancel: 'Аннулирован',
        requestReturn: 'Запрос возврата в работу',
    },

    action: {
        requestReturn: 'Запросить возврат в работу',
        rejectRequestReturn: 'Отклонить возврат в работу',
    },

    plans: {
        tooltips: {
            move: 'Перемещение',
            remarkArea: 'Область замечания',
            remarkPin: 'Метка замечания',
            selectRemark: 'Выбор замечания',
            planActions: 'Действия с чертежом',
            modelActions: 'Действия с моделью',
            openPagesPreview: 'Открыть превью страниц',
            pageNumber: 'Номер страницы',
            hideForm: 'Скрыть форму',
            openForm: 'Раскрыть форму',
            nextPage: 'Следующая страница',
            prevPage: 'Предыдущая страница',
            adjustHeight: 'Подогнать по высоте',
            adjustWidth: 'Подогнать по ширине',
            removeFromComparing: 'Удалить из сравнения',
            zoomIn: 'Приблизить',
            zoomOut: 'Отдалить',
            versionsList: 'Список версий',
        },
        form: {
            attach: 'Прикрепить файл',
            nothingHere: 'Здесь пока ничего нет',
            search: 'Поиск',
            all: 'Все',
            pgs: 'стр.',
            minAgo: 'мин. назад',
            status: 'Статус',
            removeRemark: 'Удалить замечание',
            removeGroup: 'Удалить группу',
            editRemark: 'Редактировать замечание',
            swap: 'Переместить',
            noMessages: 'Здесь пока нет сообщений',
            sureToRemoveRemark: 'Действительно хотите удалить замечание?',
            sureToRemoveGroup: 'Действительно хотите удалить группу "{name}"?',
            cancel: 'Отменить',
            remove: 'Удалить',
            relocateIn: 'Переместить в',
            remark: 'Замечание',
            setPinForClonedRemark: 'Поставьте отметку на чертеже, чтобы подтвердить копирование',
            addRemarkText: 'Добавить текст замечания',
            remarkRejectionReasonTitle: 'Причина отклонения:',
        },
        editor: {
            compare: 'Сравнить версии',
            returnToWork: 'Вернуть в работу',
            forRevision: 'На устранение замечаний',
            forChecking: 'На проверку',
            accept: { plan: 'Принять чертеж', model: 'Принять модель' },
            createNewRemark: 'Создать замечание в новой группе',
            pasteRemark: 'Вставить замечание',
            cancel: 'Отменить',
            send: 'Отправить',
            selectDesigner: 'Выберите проектировщика',
            selectExecutor: 'Выберите исполнителя',
            selectTerm: 'Выберите срок исполнения',
            sendForRevision: 'Отправить документ на доработку?',
            setParamsForRevision: 'Выберите организацию и исполнителя, а также назначьте срок сдачи',
            successSend: 'Документ успешно отправлен на доработку',
            successAccept: 'Документ успешно принят',
            successSendForChecking: 'Документ успешно отправлен на проверку и добавлен в T.Cloud',
            errorSendForChecking: 'Ошибка: необходима новая версия чертежа',
            goToProjects: 'Перейти к списку проектов',
            stayThisProject: 'Остаться в этом проекте',
            group: 'Группа',
            addIn: 'Создать замечание в',
            copyOnNew: 'Копировать на новый',
            addFileFromComparing: 'Добавить файл для сравнения',
            fillLastRemark: 'Сначала заполните предыдущее замечание',
            checkingDeadline: 'Срок проверки чертежа',
            inProduction: 'В производство',
            notSupported: 'Пока не поддерживается!',
        },
        backToTask: 'Назад к задаче',
    },

    changelog: {
        title: 'Журнал изменений',
        toFixing: 'Отправил(а) чертеж на доработку',
        toChecking: 'Отправил(а) чертеж на проверку',
        toAdopted: 'Принял(а) чертеж',
        toProduction: 'Отправил(а) чертёж в производство',
        toCreated: 'Создал(а) чертёж',
        toCancel: 'Аннулировал(а) чертёж',
    },

    task: {
        requestedProlongation: 'Запросил(а) продление срока',
        executorsList: 'Список исполнителей',
        executorsListIsEmpty: 'Исполнители не добавлены',
        addExecutor: 'Добавить исполнителя',
        createReport: 'Сформировать отчет',
        createReportAction: 'Сформировать',
        createReportModalTitle: 'Заполните параметры для формирования отчета',
        createReportFromDate: 'Начало отчетного периода:',
        createReportToDate: 'Окончание отчетного периода:',
        createReportFileName: 'Статистика по задачам с {0} по {1}.xlsx',
        createReportFileNameAcceptance: 'Отчёт по приёмке с {0} по {1}.xlsx',
        createReportFileNameWarranty: 'Отчёт по гарантии с {0} по {1}.xlsx',
        createReportVerification: 'Отчёт о нарушении сроков проверки/устранения нарушений с {0} по {1}.xlsx',
    },
};
