import Apartment from '@/ship/Models/Apartment';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { AxiosResponse } from 'axios';

export const getApartment = <D = Apartment>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`apartments/${id}`, { params });

export const getAllApartments = <D = Apartment>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/apartments`, { params });

export const createApartment = (
    name: string,
    floorId: number,
): Promise<AxiosResponse<ITransformedValue<Apartment>>> => {
    return Http.post(`/apartments`, { name, floorId });
};

export const updateApartment = (
    id: number,
    name?: string | null,
    floorId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Apartment>>> => {
    return Http.patch(`/apartments/${id}`, { id, name, floorId });
};
