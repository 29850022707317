import { AxiosResponse } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import User from '@/ship/Models/User';
import Http from '@/ship/Http';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import UserEditRequest from '@/ship/Requests/UserEditRequest';
import ForgotPasswordRequest from '@/ship/Requests/ForgotPasswordRequest';
import ResetPasswordRequest from '@/ship/Requests/ResetPasswordRequest';

export const getUserProfile = (): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.get(`/user/profile`, { params: { include: 'roles,permissions,projects,cities' } });
};

export const changeUserSettings = async (settings: { isSnow: boolean }) => {
    return Http.post(`/user/change/settings`, { ...settings });
};

export const checkUserUniqueEmail = async (email: string) =>
    (await Http.post<{ email: boolean }>('/user/check/unique/', { email })).data;

export const getUser = <D = User>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/users/${id}`, { params });

export const getUsers = <D = User>(params?: GetRequestParams) => Http.get<ITransformedValue<D[]>>(`/users`, { params });

export const getFilteredUsers = <D = User>(params: GetRequestParams = { include: 'roles', limit: 0 }) =>
    Http.get<ITransformedValue<D[]>>(`/users/get/filtered`, { params });

export const deleteUser = (id: number): Promise<AxiosResponse> => {
    return Http.delete(`/users/${id}`);
};

export const updateUser = (id: number, data: UserEditRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/users/${id}`, { id, ...data });
};

export const createUser = (data: UserEditRequest) => Http.post<ITransformedValue<User>>(`/register`, data);

export const forgotUserPassword = (data: ForgotPasswordRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/password/forgot`, data);
};

export const resetUserPassword = (data: ResetPasswordRequest): Promise<AxiosResponse<ITransformedValue<User>>> => {
    return Http.post(`/password/reset`, data);
};
