import Section from '@/ship/Models/Section';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { AxiosResponse } from 'axios';

export const getSection = <D = Section>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/sections/${id}`, { params });

export const getAllSections = <D = Section>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/sections`, { params });

export const createSection = (name: string, projectId: number): Promise<AxiosResponse<ITransformedValue<Section>>> => {
    return Http.post(`/sections`, { name, projectId });
};

export const updateSection = (
    id: number,
    name?: string | null,
    projectId?: number | null,
): Promise<AxiosResponse<ITransformedValue<Section>>> => {
    return Http.patch(`/sections/${id}`, { id, name, projectId });
};
