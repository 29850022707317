import { MutationTree } from 'vuex';
import { PrescriptionState } from '@/store/Prescription/PrescriptionRoot';

export const mutations: MutationTree<PrescriptionState> = {
    RESET_VIOLATIONS(state) {
        state.pagination = null;
        state.violations = null;
        state.lighterViolations = null;
        state.violationsAcceptance = null;
        state.violationsWarranty = null;
    },

    RESET_CALLS(state) {
        state.pagination = null;
        state.calls = null;
    },

    SET_VIOLATIONS(state, violations) {
        state.violations = violations;
    },

    GET_ALL_VIOLATIONS(state, violations) {
        if (state.violations) {
            state.violations!.push(...violations);
        } else {
            state.violations = violations;
        }
    },

    PAGINATION(state, pagination) {
        state.pagination = pagination;
    },

    GET_ALL_VIOLATIONS_ACCEPTANCE(state, violations) {
        if (state.violationsAcceptance) {
            state.violationsAcceptance!.push(...violations);
        } else {
            state.violationsAcceptance = violations;
        }
    },

    GET_ALL_VIOLATIONS_WARRANTY(state, violations) {
        if (state.violationsWarranty) {
            state.violationsWarranty!.push(...violations);
        } else {
            state.violationsWarranty = violations;
        }
    },

    GET_ALL_CALLS(state, calls) {
        if (state.calls) {
            state.calls!.push(...calls);
        } else {
            state.calls = calls;
        }
    },

    SET_REQUIREMENT(state, requirements) {
        state.requirements = requirements;
    },

    SET_VIOLATION_COUNT(state, violationCount) {
        state.violationCount = violationCount;
    },

    SET_MAIN_PACK_DATA(state, mainPackData) {
        state.mainPackData = mainPackData;
    },

    GET_VIOLATION_TIME(state, violations) {
        state.lighterViolations = violations;
    },
};
export default mutations;
