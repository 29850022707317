export default {
    customer: 'Заказчик',
    generalContractor: 'Генподрядчик',
    subContractor: 'Субподрядчик',
    project: 'Проект',
    section: 'Секция',
    floor: 'Этаж',
    typeOfWorks: 'Вид работ',
    typeOfRemarks: 'Вид',
    typeOfRooms: 'Помещение',
    subcontractor: 'Субподрядчик',
    basicRequirement: 'Основание',
    deadline: 'Срок устранения',
    photos: 'Фотографии и вложения: ',
    photosUpload: 'Загрузить фото',
    photosCount: 'Количество загруженных фотографий: ',
    description: 'Описание',
    sendButton: 'Отправить',
    upload: 'Загрузить',
    selectFile: 'Выберите файл',
    statusOfRemarks: 'Статус',
    executor: 'Исполнитель',

    setGeneralContractor: 'Укажите Генподрядчика',
    setSubContractor: 'Укажите Субподрядчика',
    setProject: 'Выберите проект',
    setSection: 'Выберите секцию',
    setFloor: 'Выберите этаж',
    setTypeOfWorks: 'Выберите вид работ',
    setTypeOfRemarks: 'Не выбран',
    setTypeOfRooms: 'Не выбрано',
    setRequirement: 'Выберите положение',

    rejectCauses: 'Причина отклонения',
    postponement: 'Перенести срок',
    create: 'Создать предписание',
    createPrescription: 'Создать предписание',
    createAct: 'Сформировать акт',
    violation: 'Предписание',
    display: 'Показать',
    edit: 'Редактировать',
    cancel: 'Отменить',
    update: 'Обновить',
    copy: 'Копировать',
    violationCatalog: 'Журнал предписаний',
    violationAcceptance: 'Приёмка квартир',
    violationWarranty: 'Гарантия',
    formCreating: 'Форма создания предписания',
    saveAndClose: 'Сохранить и закрыть',
    remarkDone: 'Замечание устранено',
    createNewRemark: 'Создать новое замечание',
    closeAsCompleted: 'Закрыть как выполненное',
};
