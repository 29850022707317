// Utils
import { keeper } from '@/plugins/PromiseKeeper/PromiseKeeper';

// Api
import { getAllPositions, getAllRoles } from '@/utils/Api';
import { getUserProfile, getUsers } from '@/utils/api/Users';
import { getAllFloors, getFloorById } from '@/utils/api/Floors';
import { getAllApartments } from '@/utils/api/Apartments';
import { getAllCities } from '@/utils/api/Cities';
import { getAllOrganisations } from '@/utils/api/Organisations';
import { getAllProjects } from '@/utils/api/Projects';
import { getAllSections } from '@/utils/api/Sections';

// Interfaces
import { ActionTree } from 'vuex';
import { RootState } from '@/store/Root/Root';

import User from '@/ship/Models/User';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Floor from '@/ship/Models/Floor';
import Project from '@/ship/Models/Project';
import Position from '@/ship/Models/Position';
import Organisation from '@/ship/Models/Organisation';
import Section from '@/ship/Models/Section';
import Apartment from '@/ship/Models/Apartment';

export const actions: ActionTree<RootState, RootState> = {
    async GET_ALL_PROJECTS({ state, commit }): Promise<Project[]> {
        if (state.projects) return state.projects;

        return keeper.get('GET_ALL_PROJECTS', async () => {
            const {
                data: { data: projects },
            } = await getAllProjects({
                limit: 0,
                include: 'users,generalContractor.users,customer.users,generalDesigners,generalDesignersIds',
            });

            commit('SET_ALL_PROJECTS', projects);

            return projects;
        });
    },

    async GET_ALL_CITIES({ state, commit }): Promise<City[]> {
        if (state.cities) {
            return state.cities;
        }

        return keeper.get('GET_ALL_CITIES', async () => {
            const {
                data: { data: cities },
            } = await getAllCities();

            commit('ADD_TO_ALL_CITIES', cities);

            return cities;
        });
    },

    async GET_ALL_ORGANISATIONS(
        { state, commit },
        params?: { forceUpdate?: boolean; requiredIncludes?: string; limit?: number },
    ): Promise<Organisation[]> {
        if (state.organisations && !params?.forceUpdate) return state.organisations;

        return keeper.get('GET_ALL_ORGANISATIONS', async () => {
            const organisations = (
                await getAllOrganisations({ include: params?.requiredIncludes, limit: params?.limit })
            )?.data?.data;

            commit('SET_ALL_ORGANISATIONS', organisations);

            return organisations;
        });
    },

    async GET_ALL_SECTIONS({ state, commit }): Promise<Section[]> {
        if (state.sections.length) {
            return state.sections;
        }

        return keeper.get('GET_ALL_SECTIONS', async () => {
            const {
                data: { data: sections },
            } = await getAllSections();

            commit('SET_ALL_SECTIONS', sections);

            return sections;
        });
    },

    async GET_ALL_FLOORS({ state, commit }): Promise<Floor[]> {
        if (state.floors) {
            return state.floors;
        }

        return keeper.get('GET_ALL_FLOORS', async () => {
            const {
                data: { data: floors },
            } = await getAllFloors();

            commit('SET_ALL_FLOORS', floors);

            return floors;
        });
    },

    async GET_ALL_APARTMENTS({ state, commit }): Promise<Apartment[]> {
        if (state.apartments) {
            return state.apartments;
        }

        return keeper.get('GET_ALL_APARTMENTS', async () => {
            const {
                data: { data: apartments },
            } = await getAllApartments();

            commit('SET_ALL_APARTMENTS', apartments);

            return apartments;
        });
    },

    async GET_FLOOR_BY_ID({ commit }, id: number): Promise<Floor> {
        const {
            data: { data: floor },
        } = await getFloorById(id);
        commit('GET_FLOOR_BY_ID', floor);
        return floor;
    },

    async GET_USER_PROFILE({ commit }): Promise<User> {
        const {
            data: { data: user },
        } = await getUserProfile();

        commit('SET_USER_PROFILE', user);

        return user;
    },

    async GET_USERS({ state, commit }, params?: { forceUpdate?: boolean }): Promise<User[]> {
        if (state.users && !params?.forceUpdate) return state.users;

        return keeper.get('GET_USERS', async () => {
            const {
                data: { data: users },
            } = await getUsers({ include: 'projects,roles,permissions', limit: 0 });

            commit('SET_USERS', users);

            return users;
        });
    },

    async GET_ALL_ROLES({ state, commit }): Promise<Role[]> {
        if (state.roles) {
            return state.roles;
        }

        return keeper.get('GET_ALL_ROLES', async () => {
            const {
                data: { data: roles },
            } = await getAllRoles();

            commit('SET_ALL_ROLES', roles);

            return roles;
        });
    },

    async GET_ALL_POSITIONS({ state, commit }): Promise<Position[]> {
        if (state.positions) {
            return state.positions;
        }

        return keeper.get('GET_ALL_POSITIONS', async () => {
            const {
                data: { data: positions },
            } = await getAllPositions();

            commit('SET_ALL_POSITIONS', positions);

            return positions;
        });
    },
};
export default actions;
