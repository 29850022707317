export default {
    error: 'Во время обращения к серверу произошла ошибка',
    typeOfWork: 'Не выбран вид работ предписания № {points}',
    typeOfWorkAcceptance: 'Не выбран вид работ',
    typeOfRoomAcceptance: 'Не выбрано помещение',
    typeOfRemark: 'Не выбран вид замечания № {remarkIndex}',
    description: 'Не указано описание предписания № {points}',
    descriptionTypeOfRemark: 'Не указано описание в замечании № {remarkIndex} вида {type}',
    content: 'Не заполнено содержание',
    photo: 'Не загружены фотографии',
    failedToUpload: 'Не удалось получить файл, попробуйте еще раз.',
    fileTooLarge: 'Слишком большой файл. Принимаем не больше {maxFileSize}',
    notAllowedFileType: 'Недопустимый тип файла',
    filesCount: 'Некорректное количество файлов',
    noAccessRights: 'Недостаточно прав доступа',
    tokenExpired: 'Токен авторизации истек',
    failedToLoadApplication: 'Не удалось загрузить приложение, напишите нам: help@t-dev.pro',
    noAccessApp: 'У Вас нет доступа к системе Т-про, напишите нам: help@t-dev.pro',
    hasNoAccessRights: 'У вас недостаточно прав для доступа к данному функционалу, напишите нам: help@t-dev.pro',
    GenerateDocumentInfo: 'Не удалось создать документ',
    GenerateDocumentName: 'Не удалось создать документ',
    GenerateDocumentPath: 'Не удалось создать документ',
    NotAllowedUploadDocument: 'В данный каталог нельзя загружать документы',
    SaveDocumentVersion: 'Не удалось создать версию документа',
    CreateResourceFailed: 'При создание произошла неизвестная ошибка',
    ForbiddenResourceFailed: 'У вас недостаточно прав для проведения данной операции в этом каталоге',
    NotFound: 'Не удалось найти запрошенный ресурс',
    NotAllowedUploadDocuments: 'Сюда нельзя загружать документы',
    notUniqueOrganisationINN: 'Организация с данным ИНН уже была создана',
    notUniqueOrganisationPhone: 'Телефон уже был ранее использован в другой организации',
    notUniqueUserEmail: 'Данный адрес почты уже был использован ранее у другого пользователя',
    MissingPDFException: `Не удалось загрузить документ. Пожалуйста, проверьте, есть ли у вас права доступа, и не был ли удалён файл`,
    InvalidPDFException: 'PDF файл повреждён. Пожалуйста, проверьте его целостность',
    DocumentAgreed:
        'Для загрузки нового чертежа необходимо расторгнуть  документ в T.Cloud или изменить его статус в T.Pro.',
};
