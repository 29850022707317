import IncludeViolation from '@/ship/Models/IncludeViolation';
import Pagination from '@/ship/Models/Pagination';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import IncludeCall from '@/ship/Models/IncludeCall';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import BasicRequirement from '@/ship/Models/BasicRequirement';

import { StoreOptions } from 'vuex';
import actions from './Actions';
import mutations from './Mutations';
import getters from './Getters';
import { namespace } from 'vuex-class';

export interface PrescriptionState {
    violations: IncludeViolation[] | null;
    pagination: Pagination | null;
    violationsAcceptance: IncludeViolationAcceptance[] | null;
    violationsWarranty: IncludeViolationWarranty[] | null;
    calls: IncludeCall[] | null;
    mainPackData: MainPackData | null;
    violationCount: ViolationCount[] | null;
    lighterViolations: IncludeViolation[] | null;
    requirements: BasicRequirement[] | null;
}

export const state: PrescriptionState = {
    violations: null,
    pagination: null,
    violationsAcceptance: null,
    violationsWarranty: null,
    calls: null,
    mainPackData: null,
    violationCount: null,
    lighterViolations: null,
    requirements: null,
};

export const root: StoreOptions<PrescriptionState> = {
    state,
    actions,
    getters,
    mutations,
};

export const PrescriptionStore = namespace('prescription');
export const PrescriptionAction = PrescriptionStore.Action;
export const PrescriptionMutation = PrescriptionStore.Mutation;
export const PrescriptionGetter = PrescriptionStore.Getter;
export const PrescriptionState = PrescriptionStore.State;

export default root;
