import { sortBy } from 'lodash';
import { MutationTree } from 'vuex';
import { RootState } from './Root';
import { TranslateResult } from 'vue-i18n';

// Models
import User from '@/ship/Models/User';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Project from '@/ship/Models/Project';
import Organisation from '@/ship/Models/Organisation';
import Position from '@/ship/Models/Position';
import Section from '@/ship/Models/Section';
import Floor from '@/ship/Models/Floor';
import Apartment from '@/ship/Models/Apartment';
import { Setting } from '@/ship/Models/User';

export const mutations: MutationTree<RootState> = {
    SET_APP_LOADING(state, payload: boolean) {
        state.isLoadingApp = payload;
    },

    SET_APPLICATION_ERROR(state, message: string | TranslateResult | null) {
        state.appErrorMessage = message;
    },

    SET_USER_PROFILE(state, user: User) {
        state.user = user;
    },

    CHANGE_USER_SETTINGS(state, setting: Setting) {
        state.user!.settings = setting;
    },

    SET_USERS(state, users: User[]) {
        state.users = users;
    },

    DELETE_FROM_USERS(state, userId: number) {
        if (state.users) state.users = state.users.filter(({ id }) => id !== userId);
    },

    ADD_TO_USERS(state, user: User) {
        if (state.users) state.users = [...state.users, user];
    },

    UPDATE_USER(state, payload: User) {
        if (state.users) {
            state.users = state.users.map((user) => {
                return user.id === payload.id ? { ...user, ...payload } : user;
            });
        }
    },

    SET_ALL_ROLES(state, roles: Role[]) {
        state.roles = roles;
    },

    SET_ALL_POSITIONS(state, positions: Position[]) {
        state.positions = positions;
    },

    SET_ALL_PROJECTS(state, projects: Project[]) {
        state.projects = projects;
    },

    ADD_TO_PROJECTS(state, projects: Project[]) {
        state.projects = sortBy(state.projects ? [...state.projects, ...projects] : projects, 'id');
    },

    UPDATE_PROJECT(state, payload: Partial<Project>) {
        if (state.projects) {
            state.projects = state.projects.map((project) => {
                return project.id === payload.id ? { ...project, ...payload } : project;
            });
        }
    },

    ADD_TO_ALL_CITIES(state, cities: City[]) {
        state.cities = sortBy(state.cities ? [...state.cities, ...cities] : cities, 'id');
    },

    UPDATE_CITY(state, payload: Partial<City>) {
        if (state.cities) {
            state.cities = state.cities.map((city) => {
                return city.id === payload.id ? { ...city, ...payload } : city;
            });
        }
    },

    SET_ALL_ORGANISATIONS(state, organisations: Organisation[]) {
        state.organisations = sortBy(organisations, 'id');
    },

    ADD_TO_ALL_ORGANISATIONS(state, organisations: Organisation[]) {
        state.organisations = sortBy(
            state.organisations ? [...state.organisations, ...organisations] : organisations,
            'id',
        );
    },

    UPDATE_ORGANISATION(state, payload: Partial<Organisation>) {
        if (state.organisations) {
            state.organisations = state.organisations.map((organisation) => {
                return organisation.id === payload.id ? { ...organisation, ...payload } : organisation;
            });
        }
    },

    SET_ALL_SECTIONS(state, sections: Section[]) {
        state.sections = sections;
    },

    ADD_TO_SECTIONS(state, section: Section) {
        state.sections = sortBy([...state.sections, section], 'id');
    },

    UPDATE_SECTION(state, payload: Partial<Section>) {
        state.sections = state.sections.map((section) => {
            return section.id === payload.id ? { ...section, ...payload } : section;
        });
    },

    SET_ALL_FLOORS(state, floors: Floor[]) {
        state.floors = sortBy(floors, 'id');
    },

    ADD_TO_FLOORS(state, floors: Floor[]) {
        state.floors = sortBy(state.floors ? [...state.floors, ...floors] : floors, 'id');
    },

    UPDATE_FLOOR(state, payload: Partial<Floor>) {
        if (state.floors) {
            state.floors = state.floors.map((floor) => {
                return floor.id === payload.id ? { ...floor, ...payload } : floor;
            });
        }
    },

    SET_ALL_APARTMENTS(state, apartments: Apartment[]) {
        state.apartments = sortBy(apartments, 'id');
    },

    ADD_TO_APARTMENTS(state, apartments: Apartment[]) {
        state.apartments = sortBy(state.apartments ? [...state.apartments, ...apartments] : apartments, 'id');
    },
};
export default mutations;
