export default {
    title: 'Уведомление от T.Pro',
    storage: 'Во время прошлой сессии произошла ошибка сети, хотите восстановить данные?',
    deletePoint: 'Вы уверены, что хотите удалить запись предписания № {index} ?',
    alert: {
        requestFail: 'Что-то пошло не так...',
        pleaseTryAgain: 'Повторите попытку позже.',
        success: 'Успешно!',
        editingDataSet: 'Изменения внесены',
    },
    description: {
        closeToAccept: 'После закрытия предписание перейдёт в статус «Принято»',
    },
    choose: {
        violation: 'Предписание № {index}',
        create: 'Создать запись предписания',
    },
    answer: {
        positive: 'Да',
        negative: 'Нет',
        confirm: 'Ок',
        understand: 'Понятно',
        cancel: 'Отменить',
        copy: 'Копировать',
    },

    confirm: {
        sureToUpdate: 'Вы уверены, что хотите обновить предписание?',
        sureToClose: 'Вы действительно хотите закрыть предписание как выполненное?',
    },
    success: {
        title: 'Запрос прошел успешно!',
    },
};
