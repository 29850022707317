import { ActionTree } from 'vuex';
import { ProjectManagementState } from '@/store/ProjectManagement/ProjectManagementRoot';

// API
import { getChartVersionStructure, getPMChart, updatePMChart } from '@/utils/api/ProjectManagement/PMChart';

import { GetRequestParams } from '@/ship/Requests/RequestParams';
import { IPMChart } from '@/ship/Models/IProjectManagement';
import PMChartEditModel from '@/ship/Models/PMChartEditModel';
import { ChartVersion } from '@/ship/Models/ProjectManagement';
import { keeper } from '@/plugins/PromiseKeeper/PromiseKeeper';

export const actions: ActionTree<ProjectManagementState, ProjectManagementState> = {
    async GET_CHART_BY_ID({ state, commit }, payload: { id: number; params?: GetRequestParams }): Promise<IPMChart> {
        if (state.chart && state.chart.id == payload.id) return state.chart;

        return keeper.get('GET_CHART_BY_ID', async () => {
            const {
                data: { data: chart },
            } = await getPMChart(payload.id, payload.params);

            commit('SET_CHART_BY_ID', chart);

            return chart;
        });
    },

    async UPDATE_CHART_BY_ID({ state, commit }, payload: { id: number; data: PMChartEditModel }): Promise<IPMChart> {
        return keeper.get('UPDATE_CHART_BY_ID', async () => {
            const {
                data: { data: chart },
            } = await updatePMChart(payload.id, payload.data);

            commit('UPDATE_CHART_BY_ID', chart);

            return chart;
        });
    },

    async GET_STRUCTURE_BY_ID({ state, commit }, id: number): Promise<ChartVersion> {
        if (state.structure && state.structure.id == id) return state.structure;
        return keeper.get('GET_STRUCTURE', async () => {
            const {
                data: { data: structure },
            } = await getChartVersionStructure(id);

            commit('SET_STRUCTURE', structure);

            return structure;
        });
    },
};
export default actions;
