const TCloudUrl = process.env.VUE_APP_CLOUD_URL ?? '';

export default [
    {
        name: 'cloud',
        path: '/cloud',
        beforeEnter() {
            location.href = TCloudUrl;
        },
    },
    {
        name: 'qlik',
        path: '/qlik',
        beforeEnter() {
            location.href = 'https://qlik.talan.group';
        },
    },
    {
        name: 'cloud.design',
        path: '/cloud/directories/:parentId/documents/:id',
        beforeEnter(to: { params: { id: string; parentId: string } }) {
            location.href = `${TCloudUrl}/0/directories/${to.params.parentId}/documents/${to.params.id}`;
        },
    },
];
