import { camelCase, flow, last, pickBy, upperFirst } from 'lodash';
import Vue, { CreateElement, RenderContext } from 'vue';
import { DateTime } from 'luxon';
import IsDayOffAPI from '@/plugins/IsdayoffAPI';
import { mainBus } from '@/main';
import { TranslateResult } from 'vue-i18n';
import { Route } from 'vue-router';

/* Files */
export const camelCaseFileName = (fileName: string) => {
    const name = last(fileName.split('/'));

    return camelCase(name!.replace(/(\.\/|\.js)/g, ''));
};
export const getFileExtension = (fileName: string) => fileName.split('.').pop();

export const getFileName = (fileName: string) => fileName.replace(/\.[^/.]+$/, '');

/* case converters */
export const splitCaps = (str: string) =>
    str
        .replace(/([a-z])([A-Z]+)/g, (m, s1, s2) => s1 + ' ' + s2)
        .replace(/([A-Z]+)([A-Z][a-z])/g, (m, s1, s2) => s1 + ' ' + s2)
        .replace(/([A-Za-z]+)(\d+)/g, (m, s1, s2) => s1 + ' ' + s2);

export const camelToSnake = (str: string) =>
    splitCaps(str)
        .replace(/\W+/g, ' ')
        .split(/\s/)
        .map((word) => (word.length > 1 && word === word.toUpperCase() ? word : word.toLowerCase()))
        .join('_');

export const snakeToCamel = (str: string) =>
    str.split('_').reduce((result, word, index) => {
        if (word === word.toUpperCase()) return result + word;
        return result + (index ? pascalCase(word) : camelCase(word));
    }, '');

export const pascalCase = flow(camelCase, upperFirst);

/* Dates */
export const isDateOverdue = (deadlineDate: string, comparingDate?: string | null) => {
    const deadline = new Date(deadlineDate);
    deadline.setDate(deadline.getDate() + 1);
    const comparing = comparingDate ? new Date(comparingDate) : new Date();
    return comparing > deadline;
};

export const fromISODateToLocalDateTime = (date?: string) =>
    date ? DateTime.fromISO(date).toLocal().toFormat('f') : '';

export const fromISODateToLocalDateTimeFull = (date?: string) =>
    date ? DateTime.fromISO(date).setLocale('ru').toFormat('dd MMMM yyyy, T') : '';

export const fromISODateToLocalDate = (date?: string) => (date ? DateTime.fromISO(date).toLocal().toFormat('D') : '');

export const fromISODateToLocal = (date?: string) =>
    date ? DateTime.fromISO(date).toLocal().toFormat('dd.MM.yyyy') : '';

export const fromLocalDateToISODate = (date?: string) =>
    date ? DateTime.fromFormat(date, 'dd.MM.yyyy').toFormat('yyyy-MM-dd') : '';

export const getCurrentDateString = () => DateTime.now().toFormat('dd.MM.yyyy');

export const getYesterdayDateString = () => new Date(Date.now() - 86400000).toLocaleDateString('ru-RU');

export const getNextDayDateString = (date?: string) => {
    if (date) {
        const currDate = DateTime.fromFormat(date, 'dd.MM.yyyy');
        const nextDay = currDate.plus({ days: 1 });
        return nextDay.toFormat('dd.MM.yyyy');
    }
    return '';
};

export const compareByIsoDateDesc = (a: string, b: string) => {
    const dateA = DateTime.fromISO(a),
        dateB = DateTime.fromISO(b);

    if (dateA === dateB) return 0;
    return dateA < dateB ? 1 : -1;
};

export const getWorkDateAfterDays = async (workDays: number) => {
    let date = DateTime.now();

    const period = {
        start: DateTime.now().plus({ day: 1 }).toJSDate(),
        end: DateTime.now().plus({ year: 1 }).toJSDate(),
    };
    const holidaysForYear: boolean[] = await IsDayOffAPI.period(period);

    for (const isHoliday of holidaysForYear) {
        date = date.plus({ day: 1 });
        if (!isHoliday) workDays--;
        if (!workDays) break;
    }

    return date;
};

/* Components */
/**
 * Default functional render component for slots
 */
export const VNodeFunctional = {
    functional: true,
    render: (h: CreateElement, ctx: RenderContext) => ctx.props.vnode,
};

export const getComponentFunctions = <T extends Vue>(component: T) => {
    return pickBy(component, (value, key) => typeof value === 'function' && /^[a-z]+/g.test(key));
};

export const isElementScrolledToBottom = (el: Element) => el.clientHeight + Math.ceil(el.scrollTop) >= el.scrollHeight;

export const isElementScrolledToTop = (el: Element) => el.scrollTop === 0;

const transformScroll = (event: Event) => {
    if (!(event instanceof WheelEvent) || !event.deltaY) return;
    const target = event.currentTarget as HTMLElement;

    target.scrollLeft += event.deltaY + event.deltaX;
    event.preventDefault();
};

export const addHorizontalScroll = (element: Element, vm: Vue) => {
    element.addEventListener('wheel', transformScroll);
    vm.$once('hook:destroyed', () => element.removeEventListener('wheel', transformScroll));
};

/* Others */
/**
 * Returns a random number between min and max
 */
export const getRandomNumber = (min: number, max: number) => Math.round(Math.random() * (max - min) + min);

export const between = (value: number, min: number, max: number): boolean => value >= min && value <= max;

export function generateHashId(prefix = ''): string {
    const hash = Math.random().toString(36).slice(2, 12);
    return `${prefix}${hash}`;
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const hasSearch = (str: string, search: string) => str.toLowerCase().includes(search.toLowerCase().trim());

// @ts-ignore
export const getEnumKeys = (obj: object) => Object.keys(obj).filter(isNaN);

export const isInterface = <T>(object: any, requiredFields: string[]): object is T => {
    return requiredFields.every((field) => field in object);
};

/**
 * check id and redirect to 404 page if id is broken
 */
export const checkRouteId = (id: number | string) => {
    if (!(Number.isInteger(id) && +id > 0)) {
        return mainBus.$router.push({ name: 'error.404' });
    }
};

/**
 * Formatters
 */
export const formatSum = (num: number) => {
    return formatDigits(num, 2);
};

export const formatQuantity = (num: number) => {
    return formatDigits(num, 3);
};

export const formatDigits = (num: number, cnt: number) => {
    let rounded = num;
    let fraction = 0;

    if (num >= 0) {
        rounded = Math.floor(num);
        fraction = num - rounded;
    } else {
        rounded = Math.ceil(num);
        fraction = Math.abs(num) + rounded;
    }

    const fractionStr = fraction !== 0 ? fraction.toFixed(cnt) : '';
    return rounded.toLocaleString('ru-RU') + fractionStr.slice(1);
};

/**
 * Set operations
 */

export const isSuperset = (set: Set<any>, subset: Set<any>) => {
    for (const elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }
    return true;
};

/**
 * Create tooltip
 */
export const tooltipForContent = (content: string | TranslateResult) => {
    return {
        content,
        placement: 'bottom',
        defaultHideOnTargetClick: false,
        autoHide: false,
    };
};

export const getQueryProperty = (route: Route, prop: string): string => {
    let property = '';
    if (!Array.isArray(route.query[prop]) && route.query[prop]) {
        property = route.query[prop] as string;
    }
    return property;
};

/**
 * @description Find and optionally replace an element in depth
 * @param {Array} arr - Array to search in
 * @param {Object} predicate - Element that should be found
 * @param {String} childrenPath - Path to nested children
 * @param {Object | null} [newObject] - New object to replace the found one
 * @returns {Object | null} - Found object or null
 */
export const findInDepth = <T extends object>(
    arr: T[],
    predicate: Partial<T>,
    childrenPath: string,
    newObject: T | null = null,
): T | null => {
    const getNestedChildren = (obj: any, path: string) => path.split('.').reduce((acc, part) => acc?.[part], obj);

    for (let i = 0; i < arr.length; i++) {
        const el = arr[i];

        if (Object.keys(predicate).every((key) => el[key as keyof T] === predicate[key as keyof T])) {
            if (newObject !== null) {
                Vue.set(arr, i, newObject); // Ensure reactivity
            }
            return el;
        }

        const childTasks = getNestedChildren(el, childrenPath);

        if (childTasks && Array.isArray(childTasks)) {
            const found = findInDepth(childTasks, predicate, childrenPath, newObject);
            if (found) return found;
        }
    }
    return null;
};
