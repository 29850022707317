import { AxiosResponse } from 'axios';
import Http from '@/ship/Http';

// Requests
import { GetRequestParams } from '@/ship/Requests/RequestParams';

// Interfaces
import Role from '@/ship/Models/Role';
import Position from '@/ship/Models/Position';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import IFile from '@/ship/Models/IFile';
import ICitiesWithProjects from '@/ship/Models/ICitiesWithProjects';

export const createTemporaryFile = async (file: File): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await Http.post<void, AxiosResponse<ITransformedValue<IFile>>>(`/temporary-file`, formData);

    return response.data.data;
};

export const getOfferURL = (): Promise<AxiosResponse<string>> => Http.get(`/offer`);

export const getPriceURL = (): Promise<AxiosResponse<string>> => Http.get(`/price`);

//used in AdministrationUserForm
export const getAllRoles = <D = Role>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/roles`, { params });

//used in root/actions
export const getAllPositions = (): Promise<AxiosResponse<ITransformedValue<Position[]>>> => {
    return Http.get(`/positions`);
};
