import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { AxiosResponse } from 'axios';
import {
    Design,
    DesignCreateModel,
    DesignHistory,
    DesignRemark,
    DesignRemarkGroup,
    DesignTask,
    DesignTaskToSend,
    DesignUpdateModel,
    IDesignFilters,
    IDesignTaskFilters,
} from '@/ship/Models/Design';
import { formatToSendAny } from '@/utils/ModelPacking';
import IFile from '@/ship/Models/IFile';
import { Dictionary } from 'lodash';

export const getDesigns = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<Design[]>>(`/designs`, { params });
    return response.data;
};

export const getDesign = (id: number | string): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.get<ITransformedValue<Design>>(
        `/designs/${id}?include=project.users,project.generalDesigners,drawings,author`,
    );

export const deleteDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/delete`);

export const deleteDesigns = (ids: number[]): Promise<AxiosResponse> => Http.post(`/designs/multi/delete`, { ids });

export const updateDesign = (id: number, data: DesignUpdateModel): Promise<AxiosResponse> =>
    Http.post(`/designs/${id}/change`, formatToSendAny(data, ['deadline']));

export const acceptDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/accept`);

export const toWorkDesign = (id: number): Promise<AxiosResponse> => Http.get(`/designs/${id}/to-work`);

export const returnCompletedDesign = (id: number): Promise<AxiosResponse> =>
    Http.get(`/designs/${id}/complete-without-file`);

/**
 * Отправить чертёж по проектированию в производство
 */
export const toProductionDesign = (id: number) => Http.get(`/designs/${id}/to-production`);

/**
 * Отправить несколько чертежей по проектированию в производство
 */
export const toProductionSeveralDesigns = (ids: number[]) => Http.post(`/designs/change/to-production`, { ids });

export const createDesign = (data: DesignCreateModel): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.post(`/designs`, formatToSendAny(data));

export const createDesigns = (data: DesignCreateModel[]): Promise<AxiosResponse<ITransformedValue<Design[]>>> =>
    Http.post(`/designs/multi`, { data: formatToSendAny(data) });

export const changeDesignExecutor = (designId: number, executorId: number): Promise<AxiosResponse> =>
    Http.get(`/designs/${designId}/change/executor/${executorId}`);

// Design group
export const updateDesignGroup = (group: DesignRemarkGroup): Promise<AxiosResponse> =>
    Http.post(`/designs/group/${group.id}`, formatToSendAny(group));

export const deleteDesignGroup = (id: number): Promise<AxiosResponse> => Http.get(`/designs/group/${id}/delete`);

// Design remark
export const createDesignRemark = (
    designId: number,
    remark: DesignRemark,
): Promise<AxiosResponse<ITransformedValue<DesignRemarkGroup>>> => Http.post(`/designs/${designId}/task/`, remark);

export const getDesignRemark = async (remarkId: number): Promise<DesignRemark> =>
    (await Http.get(`/designs/task/${remarkId}`)).data.data;

export const updateDesignRemark = (remark: DesignRemark): Promise<AxiosResponse> =>
    Http.post(`/designs/task/${remark.id}`, remark);

export const deleteDesignRemark = (id: number): Promise<AxiosResponse> => Http.get(`/designs/task/${id}/delete`);

export const changeDesignRemarkGroup = (remarkId: number, groupId: number): Promise<AxiosResponse> =>
    Http.get(`/designs/task/${remarkId}/change/group/${groupId}`);

export const attachFilesToRemark = <D = DesignRemark>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/task/${id}/files`, { fileIds }, { params });

export const removeFilesFromRemark = <D = DesignRemark>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/task/${id}/files/delete`, { fileIds }, { params });

// Design task
export const createDesignTask = (task: DesignTaskToSend): Promise<AxiosResponse<ITransformedValue<DesignTask>>> =>
    Http.post(`/designs/author-task`, formatToSendAny(task, ['deadline']), {
        params: { include: 'files,author,authors.affiledBy,executors.affiledBy,changeDeadline' },
    });

export const updateDesignTask = (task: DesignTaskToSend): Promise<AxiosResponse<ITransformedValue<DesignTask>>> =>
    Http.post(`/designs/author-task/${task.id}`, formatToSendAny(task, ['deadline']), {
        params: { include: 'files,author,authors.affiledBy,executors.affiledBy,changeDeadline' },
    });

export const getDesignTasksById = async (designId: number) =>
    (
        await Http.get<ITransformedValue<DesignTask[]>>(`/designs/${designId}/author-tasks`, {
            params: {
                include: 'design,files,author,authors.affiledBy,executors.affiledBy,changeDeadline',
                sortedBy: 'desc',
            },
        })
    ).data.data;

export const getDesignTasksNames = async (name: string) =>
    (await Http.post<ITransformedValue<string[]>>(`/designs/find-author-tasks-names`, { name })).data.data;

export const uploadDesignTaskFile = async (params: { file: File; process: number }): Promise<IFile> => {
    const formData = new FormData();
    formData.append('file', params.file);
    const config = {
        onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
            (params.process = Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    };

    const response = await Http.post(`/designs/load/author-task/file`, formData, config);
    return response.data.data;
};

export const getAllDesignTasks = <D = DesignTask>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/get/author-tasks`, { params });

export const getAllAffiledDesignTasks = <D = DesignTask>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/get/author-tasks/affiled`, { params });

export const getDesignTask = <D = DesignTask>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/designs/author-task/${id}`, { params });

export const attachFilesToDesignTask = <D = DesignTask>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/author-task/${id}/files`, { fileIds }, { params });

export const removeFilesFromDesignTask = <D = DesignTask>(id: number, fileIds: number[], params?: GetRequestParams) =>
    Http.post<ITransformedValue<D>>(`/designs/author-task/${id}/files/delete`, { fileIds }, { params });

// Design filters
export const getDesignFilters = async () => (await Http.get<IDesignFilters>('/designs/filters/get')).data;

// Tasks filters
export const getTasksFilters = async () => (await Http.get<IDesignTaskFilters>('/designs/tasks/filters/get')).data;

// Design changelog
export const getDesignHistory = <D = DesignHistory>(designId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/${designId}/history`, { params });

export const getDesignTaskHistory = <D = DesignHistory>(taskId: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/designs/tasks/${taskId}/history`, { params });

// Design image
/**
 * Initialization for parsing pdf to images
 * @param params documentSecret - drawing uid, url - drawing url in tcloud
 * @param headers auth
 * @returns documentSecret - drawing uid, pagesCount - number of pages
 */
export const postDesignPageImage = <D = { documentSecret: string; pagesCount: number }>(
    params: { documentSecret: string; url: string },
    headers?: Dictionary<string>,
) => Http.post<D>(`/pdf-reader/export-pdf`, params, headers);

/**
 * Get image url for page
 * @param params documentSecret - drawing uid, page - page number
 * @returns url - image url for page
 */
export const getDesignPageImageUrl = async (documentSecret: string, page: number = 1): Promise<string> => {
    const response = await Http.get(`/pdf-reader/get-page/${documentSecret}/${page}`, { responseType: 'blob' });
    return URL.createObjectURL(response.data);
};
