import Http from '@/ship/Http';
import { Design, IDesignFilters } from '@/ship/Models/Design';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { AxiosResponse } from 'axios';

export const getModelFilters = async () => (await Http.get<IDesignFilters>('/design-models/filters/get')).data;

export const getModels = async (params?: GetRequestParams) => {
    const response = await Http.get<ITransformedValue<Design[]>>(`/design-models`, { params });
    return response.data;
};

export const getModel = (id: number | string): Promise<AxiosResponse<ITransformedValue<Design>>> =>
    Http.get<ITransformedValue<Design>>(
        `/design-models/${id}?include=project.users,project.generalDesigners,drawings,author`,
    );
