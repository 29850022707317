import CreateViolationRequest from '@/ship/Requests/CreateViolationRequest';
import Http from '@/ship/Http';
import { AxiosResponse } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import IncludeViolation from '@/ship/Models/IncludeViolation';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import { FiltersAcceptance } from '@/ship/Models/Acceptance';
import { FiltersWarranty } from '@/ship/Models/IPrescriptionFilterWarranty';
import History from '@/ship/Models/History';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import CreatePrescriptionWarrantyRequest from '@/ship/Requests/CreatePrescriptionWarrantyRequest';
import IncludeCall from '@/ship/Models/IncludeCall';
import CreateCallRequest from '@/ship/Requests/CreateCallRequest';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import CreateDrawingRequest from '@/ship/Requests/CreateDrawingRequest';
import Drawing from '@/ship/Models/Drawing';
import CreateReportRequest from '@/ship/Requests/CreateReportRequest';
import LoadViolationsFile from '@/ship/Models/LoadViolationsFile';
import CreatePrescriptionAcceptanceRequest from '@/ship/Requests/CreatePrescriptionAcceptanceRequest';
import CompleteRemarkAcceptanceRequest from '@/ship/Requests/CompleteRemarkAcceptanceRequest';
import CreateRemarkAcceptanceRequest from '@/ship/Requests/CreateRemarkAcceptanceRequest';
import CompleteViolationRequest from '@/ship/Requests/CompleteViolationRequest';
import Violation from '@/ship/Models/Violation';
import ICitiesWithProjects from '@/ship/Models/ICitiesWithProjects';
import BasicRequirement from '@/ship/Models/BasicRequirement';

export const updateViolation = (id: number, data: CreateViolationRequest) => {
    return Http.post(`/violations/${id}`, data);
};

export const getViolations = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolation[]>>> => {
    return Http.get(`/violations?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getAllTypesViolations = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolation[]>>> => {
    return Http.get(`/all-types-violations?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getViolationById = (id: number): Promise<AxiosResponse<ITransformedValue<IncludeViolation>>> => {
    return Http.get(`/violations/${id}`);
};

export const getViolationsAcceptance = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance[]>>> => {
    return Http.get(
        `/violations-acceptance?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}&include=tasks`,
    );
};

export const getAllViolationsAcceptance = (
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance[]>>> => {
    return Http.get(`/violations-acceptance?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&limit=0`);
};

export const getViolationsAcceptanceById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationAcceptance>>> => {
    return Http.get(
        `/violations-acceptance/${id}?include=tasks.photo,general_contractor,customer,project,tasks.completes,tasks.completes.photo,tasks.completes.document,document`,
    );
};

export const getFiltersAcceptance = (): Promise<AxiosResponse<FiltersAcceptance>> => {
    return Http.get(`/violations-acceptance/get/filters`);
};

export const getFiltersWarranty = (): Promise<AxiosResponse<FiltersWarranty>> => {
    return Http.get(`/violation-guarantees/get/filters`);
};

export const getPrescriptionHistory = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violations/${id}/history`, { params });

export const getPrescriptionHistoryAcceptance = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violations-acceptance/${id}/history`, { params });

export const getPrescriptionHistoryWarranty = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/violation-guarantees/${id}/history`, { params });

export const getViolationsWarranty = (
    page: number,
    search: string,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationWarranty[]>>> => {
    return Http.get(`/violation-guarantees?search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`);
};

export const getViolationsWarrantyById = (
    id: number,
): Promise<AxiosResponse<ITransformedValue<IncludeViolationWarranty>>> => {
    return Http.get(
        `/violations-guarantees/${id}?include=general_contractor,customer,project,act,subcontractor,executors`,
    );
};

export const createPrescriptionWarranty = (id: number) => {
    return Http.get(`/violation-guarantees/${id}/to-work`);
};

export const updatePrescriptionWarranty = (data: CreatePrescriptionWarrantyRequest) => {
    return Http.put(`/violation-guarantees/${data.id}`, data);
};

export const completePrescriptionWarranty = (data: { id: number; description: string; photo_ids: number[] }) => {
    return Http.put(`/violation-guarantees/${data.id}/complete?include=act,subcontractor,executors`, data);
};

export const changeExecutorWarranty = (data: {
    id: number;
    executors_ids?: number[];
    subcontractor_id?: number | null;
}) => {
    return Http.put(`/violation-guarantees/${data.id}/change/executors`, data);
};

export const getCalls = (page: number, search: string): Promise<AxiosResponse<ITransformedValue<IncludeCall[]>>> => {
    return Http.get(
        `/request-log?include=project,author,executor,responsible&search=${search}&searchJoin=and&orderBy=id&sortedBy=desc&page=${page}`,
    );
};

export const createCall = (params: CreateCallRequest) => {
    return Http.post(`/request-log`, params);
};

export const approveCall = (params: { id: number; responsible_id: number }) => {
    return Http.post(`/request-log/${params.id}/approve`, params);
};

export const toWorkCall = (params: { id: number; executor_id: number }) => {
    return Http.post(`/request-log/${params.id}/inWork`, params);
};

export const cancelCall = (params: {
    id: number;
    result_base: string;
    result_comment: string;
    result_photo: number[];
}) => {
    return Http.post(`/request-log/${params.id}/cancel`, params);
};

export const rejectCall = (params: { id: number; result_comment: string; result_photo: number[] }) => {
    return Http.post(`/request-log/${params.id}/reject`, params);
};

export const getCallById = (id: number): Promise<AxiosResponse<ITransformedValue<IncludeCall>>> => {
    return Http.get(`/request-log/${id}?include=project,author,executor,responsible,photo,result_photo`);
};

export const acceptCall = (id: number): Promise<AxiosResponse<ITransformedValue<IncludeCall>>> => {
    return Http.get(`/request-log/${id}/accept`);
};

export const getPrescriptionHistoryCallLog = <D = History>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/request-log/${id}/history`, { params });

export const getPrescriptionHomeCitiesWithProjects = async () => {
    return (await Http.get<ITransformedValue<ICitiesWithProjects[]>>(`/profile/cities-with-projects`)).data.data;
};

/**
 * Gets packed data for prescriptions
 */
export const getMainPackData = (): Promise<AxiosResponse<MainPackData>> => {
    return Http.get(`/get_main_pack_data`);
};

export const getViolationStatuses = (data: { cities: number[] }): Promise<AxiosResponse<ViolationCount>> => {
    return Http.post(`/cities/violations/count`, data);
};

export const setStatusAccept = (id: number) => {
    return Http.get(`/violations/${id}/accept`);
};

export const setStatusAcceptSubtask = (ids: { violationId: number; taskId: number }) => {
    return Http.get(`/violations/${ids.violationId}/task/${ids.taskId}/accept`);
};

export const rejectStatus = (id: number, description: { description: string }) => {
    return Http.post(`/violations/${id}/inWork`, description);
};

export const rejectStatusSubtask = (
    ids: { violationId: number; taskId: number },
    data: { description: string; photoId: number[] },
) => {
    return Http.post(`/violations/${ids.violationId}/task/${ids.taskId}/inWork`, data);
};

export const postponementApproval = (id: number, data: { deadline: string; description: string }) => {
    return Http.post(`/violations/${id}/toPostponementApproval`, data);
};

export const postponementApprovalAccept = (id: number, postponementApprovalId: number) => {
    return Http.get(`/violations/${id}/postponementApproval/${postponementApprovalId}/accept`);
};

/* Create data */

export const createDrawing = (data: CreateDrawingRequest): Promise<AxiosResponse<{ data: Drawing }>> => {
    return Http.post(`/drawings`, data);
};

export const createReports = (data: CreateReportRequest) => {
    return Http.post(`/violations`, data);
};

export const createViolation = (data: CreateViolationRequest) => {
    return Http.post(`/violations`, data);
};

export const createLoadViolationsFile = (
    data: FormData,
): Promise<AxiosResponse<ITransformedValue<LoadViolationsFile>>> => {
    return Http.post(`/violations/photo/load`, data);
};

export const createForm = (id: number): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`/violations/${id}/pdf`);
};

export const formViolationAcceptance = (id: number): Promise<AxiosResponse<ITransformedValue<string>>> => {
    return Http.get(`violations-acceptance/${id}/act`);
};

export const createPrescriptionAcceptance = (data: CreatePrescriptionAcceptanceRequest) => {
    return Http.post(`/violations-acceptance`, data);
};

export const completeRemarkAcceptance = (data: CompleteRemarkAcceptanceRequest) => {
    return Http.post(
        `/violations-acceptance/task/${data.task_id}/complete?include=tasks.photo,general_contractor,customer,project,tasks.completes,tasks.completes.photo,tasks.completes.document,document`,
        data,
    );
};

export const changeExecutorRemarkAcceptance = (data: {
    task_id: number;
    executors_ids?: number[];
    subcontractor_id?: number | null;
}) => {
    return Http.put('/violations-acceptance/change/executors', data);
};

export const createRemarkAcceptance = (data: CreateRemarkAcceptanceRequest) => {
    return Http.post(`/violations-acceptance/task`, data);
};

export const updateRemarkAcceptance = (data: CreateRemarkAcceptanceRequest) => {
    return Http.put(`/violations-acceptance/task/${data.id}`, data);
};

export const changeDocumentAcceptance = (data: { violation_id: number; document_id: number }) => {
    return Http.post(`/violations-acceptance/change/document?include=document`, data);
};

export const cancelRemarkAcceptance = (id: number) => {
    return Http.post(
        `/violations-acceptance/task/${id}/cancel?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const changeDeadlineAcceptance = (data: { violation_id: number; deadline: string }) => {
    return Http.post(`violations-acceptance/change/deadline`, data);
};

export const acceptTypeOfRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/accept-type/${id}?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const cancelTypeOfRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/cancel-type/${id}?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const acceptRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/task/${id}/accept?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const rejectRemarkAcceptance = (id: number) => {
    return Http.get(
        `violations-acceptance/task/${id}/reject?include=tasks.completes,tasks.completes.photo,tasks.completes.document,tasks.photo,general_contractor`,
    );
};

export const getTypicalRemarksAcceptance = (data: { typeName: string }) => {
    return Http.post(`/violations/get/descriptions`, data);
};

/* Complete Violation */

export const violationComplete = (data: CompleteViolationRequest) => {
    return Http.post(`/violations/${data.id}/complete`, data);
};

export const subtaskComplete = (
    id: { violationId: number; taskId: number },
    data: { description: string; photoId: number[] },
) => {
    return Http.post(`/violations/${id.violationId}/task/${id.taskId}/complete`, data);
};

export const violationInWork = (id: string) => {
    return Http.post(`/violations/${id}/inWork`);
};

/* Search Violations */

export const searchViolation = (id: number): Promise<AxiosResponse<Violation>> => {
    return Http.get(`/violations/?search=id:${id}`);
};

/**
 * Change executors and subcontructor
 *
 * Access: Authenticated Customer/General Constructor
 */
export const changeExecutors = (
    id: number,
    data: { executorsIds?: number[]; executorId?: number; subcontractorId?: number },
) => {
    return Http.post(`/violations/${id}/change/executors`, data);
};

/* delete violation */

export const deleteViolation = (id: number) => {
    return Http.delete(`/violations/${id}`);
};

export const getAllBasicRequirement = (): Promise<AxiosResponse<ITransformedValue<BasicRequirement[]>>> => {
    return Http.get(`/basicrequirements?limit=0`);
};
