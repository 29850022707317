// Interfaces
import {
    Design,
    DesignCreateModel,
    DesignDrawing,
    DesignRemark,
    DesignRemarkGroup,
    DesignUpdateModel,
} from '@/ship/Models/Design';
import { DesignState } from '@/store/Design/DesignRoot';
import { ActionTree } from 'vuex';
import ITab from '@/ship/Models/ITab';

// API
import {
    createDesigns,
    deleteDesignGroup,
    deleteDesignRemark,
    deleteDesigns,
    getDesign,
    updateDesign,
} from '@/utils/api/Designs';
import { getModel } from '@/utils/api/Models';

// Utils
import { formatToEditAny } from '@/utils/ModelPacking';
import { invoke, uniqBy } from 'lodash';
import VDesigningPlansForm from '@/components/Designing/VDesigningPlansForm.vue';
import VDesigningCanvasPointer from '@/components/Designing/VDesigningCanvasPointer.vue';
import VDesigningIframePointer from '@/components/Designing/VDesigningIframePointer.vue';

export const actions: ActionTree<DesignState, DesignState> = {
    SET_DESIGN_PAGE_LOADING({ commit }, loading: boolean) {
        commit('SET_DESIGN_PAGE_LOADING', loading);
    },

    async REMOVE_DESIGNS({ commit }, designs: Design[]): Promise<void> {
        await deleteDesigns(designs.map(({ id }) => id!));
    },

    async UPDATE_DESIGN({ dispatch }, params: { designId: number; model: DesignUpdateModel }): Promise<Design> {
        const { id, isModel } = (await updateDesign(params.designId, params.model)).data.data;
        const reloadParams = { designId: id, isModel };
        return await dispatch('RELOAD_DESIGN', reloadParams);
    },

    async RELOAD_DESIGN({ commit }, reloadParams: { designId: number; isModel: boolean }): Promise<Design> {
        const designId = reloadParams.designId;

        if (reloadParams.isModel) {
            const rawDesign = (await getModel(designId)).data.data;
            const newDesign = formatToEditAny(rawDesign, ['deadline']);
            return newDesign;
        } else {
            const rawDesign = (await getDesign(designId)).data.data;
            const newDesign = formatToEditAny(rawDesign, ['deadline']);
            return newDesign;
        }
    },

    async CREATE_DESIGNS({ commit }, creatingDesigns: DesignCreateModel[]): Promise<Design[]> {
        const response = await createDesigns(creatingDesigns)
            .then(({ data }) => data.data)
            .catch(() => []);
        const designs: Design[] = response.map((design) => formatToEditAny(design, ['deadline']));
        return designs;
    },

    // Plans page
    SET_PLAN_FORM({ getters, commit }, form: VDesigningPlansForm) {
        commit('SET_PLAN_FORM', { form, tab: getters.currentTab });
    },

    SET_PLAN_MAIN_EDITOR({ getters, commit }, editor: VDesigningCanvasPointer) {
        commit('SET_PLAN_MAIN_EDITOR', { editor, tab: getters.currentTab });
    },

    SET_PLAN_SECOND_EDITOR({ getters, commit }, editor: VDesigningCanvasPointer) {
        commit('SET_PLAN_SECOND_EDITOR', { editor, tab: getters.currentTab });
    },

    SET_CURRENT_DRAWING({ getters, commit }, drawing: DesignDrawing | null) {
        commit('SET_CURRENT_DRAWING', { drawing, tab: getters.currentTab });
    },

    SET_SECOND_DRAWING({ getters, commit }, drawing: DesignDrawing | null) {
        commit('SET_SECOND_DRAWING', { drawing, tab: getters.currentTab });
    },

    SET_SELECTED_REMARK(
        { commit, getters },
        params: {
            isSecondEditor: boolean;
            selectedRemark?: DesignRemark;
        },
    ) {
        const drawing = params.isSecondEditor ? getters.secondDrawing : getters.currentDrawing;
        const editor = params.isSecondEditor ? getters.secondEditor : getters.mainEditor;

        if (drawing.groups) {
            drawing.groups.data.forEach((group: DesignRemarkGroup) => {
                group.tasks?.data.forEach((remark) => {
                    const isSelectedRemark = remark.id === params.selectedRemark?.id;
                    commit('SET_REMARK_VALUE', { remark, key: 'selected', value: isSelectedRemark });

                    if (isSelectedRemark) {
                        commit('SET_GROUP_VALUE', { group, key: 'open', value: true });
                        invoke(editor, 'teleportCameraToRemark', remark);
                    }
                });
                commit('REPLACE_GROUP_IN_DRAWING', { drawing, group });
            });
        }

        invoke(editor, 'setPage', params.selectedRemark?.pageNumber);
        invoke(editor, 'drawCanvas');
        invoke(editor, 'drawTanglSceneRemarks');
        invoke(getters.planForm, 'onRemarkSelection', params.isSecondEditor);
    },

    async REMOVE_REMARK(
        { commit, dispatch, getters },
        params: { removableGroup: DesignRemarkGroup; removableRemark: DesignRemark },
    ) {
        if (params.removableGroup.tasks!.data.length <= 1) return dispatch('REMOVE_GROUP', params.removableGroup);

        await deleteDesignRemark(params.removableRemark.id!);
        const filteredTasks = params.removableGroup.tasks!.data.filter(({ id }) => id !== params.removableRemark.id!);
        commit('SET_GROUP_VALUE', { group: params.removableGroup, key: 'tasks', value: { data: filteredTasks } });

        dispatch('RERENDER_EDITORS');
    },

    async REMOVE_GROUP({ commit, getters, dispatch }, removableGroup: DesignRemarkGroup) {
        await deleteDesignGroup(removableGroup.id!);
        commit('REMOVE_GROUP', { drawing: getters.currentDrawing, removableGroup });
        dispatch('RERENDER_EDITORS');
    },

    RERENDER_EDITORS({ getters }) {
        invoke(getters.mainEditor, 'drawCanvas');
        invoke(getters.mainEditor, 'drawTanglSceneRemarks');
        invoke(getters.secondEditor, 'drawCanvas');
    },

    // Plan tabs

    CREATE_HEADER_TAB({ commit, dispatch }, params: { tab: ITab; disableSelectOnCreation?: boolean }) {
        commit('CREATE_HEADER_TAB', params.tab);
        if (!params.disableSelectOnCreation) dispatch('SELECT_HEADER_TAB', params.tab.id);
    },

    SELECT_HEADER_TAB({ commit }, id: number) {
        commit('SELECT_HEADER_TAB', id);
        commit('SAVE_TABS');
    },

    CLOSE_HEADER_TAB({ commit }, id: number) {
        commit('CLOSE_HEADER_TAB', id);
        commit('SAVE_TABS');
    },

    async LOAD_HEADER_TABS({ commit, dispatch, state }) {
        const rawData = localStorage.getItem('designTabs');
        let tabs: ITab[] = rawData ? JSON.parse(rawData) : [];

        const openedTab = [...tabs, ...state.planTabs].find(({ isOpen }) => isOpen);
        tabs = uniqBy([...tabs, ...state.planTabs], 'id');
        commit('SET_TABS', tabs);

        dispatch('SELECT_HEADER_TAB', openedTab?.id ?? -1);
    },

    CLEAR_TABS({ commit }) {
        commit('CLEAR_TABS');
    },

    SET_CLONED_REMARK({ commit, getters }, remark: DesignRemark | null) {
        commit('SET_CLONED_REMARK', { currentDrawing: getters.currentDrawing, remark });
    },

    // Model page

    SET_MODEL_MAIN_EDITOR({ getters, commit }, editor: VDesigningIframePointer) {
        commit('SET_MODEL_MAIN_EDITOR', { editor, tab: getters.currentTab });
    },

    // Model tabs

    CREATE_MODEL_HEADER_TAB({ commit, dispatch }, params: { tab: ITab; disableSelectOnCreation?: boolean }) {
        commit('CREATE_MODEL_HEADER_TAB', params.tab);
        if (!params.disableSelectOnCreation) dispatch('SELECT_MODEL_HEADER_TAB', params.tab.id);
    },

    SELECT_MODEL_HEADER_TAB({ commit }, id: number) {
        commit('SELECT_MODEL_HEADER_TAB', id);
        commit('SAVE_MODEL_TABS');
    },

    CLOSE_MODEL_HEADER_TAB({ commit }, id: number) {
        commit('CLOSE_MODEL_HEADER_TAB', id);
        commit('SAVE_MODEL_TABS');
    },

    async LOAD_MODEL_HEADER_TABS({ commit, dispatch, state }) {
        const rawData = localStorage.getItem('modelTabs');
        let tabs: ITab[] = rawData ? JSON.parse(rawData) : [];

        const openedTab = [...tabs, ...state.modelTabs].find(({ isOpen }) => isOpen);
        tabs = uniqBy([...tabs, ...state.modelTabs], 'id');
        commit('SET_MODEL_TABS', tabs);

        dispatch('SELECT_MODEL_HEADER_TAB', openedTab?.id ?? -1);
    },

    CLEAR_MODEL_TABS({ commit }) {
        commit('CLEAR_MODEL_TABS');
    },
};
export default actions;
