export default {
    title: 'Управление проектами',

    projects: {
        menu: 'Проекты',
        title: 'Мои проекты',
        code: 'Код',
        uploadImage: 'Загрузить изображение',
    },

    status: {
        agreed: 'Согласован',
        approved: 'Утвержден',
        cancelled: 'Аннулирован',
        completed: 'Завершен',
        constructionUnderway: 'Ведется строительство',
        decidingLaunch: 'Принимается решение о запуске',
        designStarted: 'Запущено проектирование',
        notAgreed: 'Не согласован',
        onApproval: 'На согласовании',
        planned: 'Запланирован',
        prepared: 'Подготовлен',
        stopped: 'Остановлен',
        workStopped: 'Работы остановлены',
    },

    calendarType: {
        fiveDays: 'Рабочий календарь на год (5 дневка)',
        sevenDays: 'Рабочий календарь на год (7 дневка)',
    },

    project: {
        globalStatus: {
            active: 'Активный',
            completed: 'Завершен',
        },
        subdevision: {
            novation: 'Новация',
            design: 'Проектирование',
            tenders: 'Тендеры',
            construction: 'Строительство',
            seo: 'СЭО',
            consolidated: 'Сводный',
        },
    },

    charts: {
        menu: 'Графики',
        title: 'Графики',
        add: 'Создать график',
        openChart: 'Открыть график',
        openStructure: 'Открыть структуру',
        chartName: 'Наименование графика',
    },

    chart: {
        menu: 'Графики',
        change: 'Изменить информацию',
        versionCreation: 'Создание версии',
        versionEdit: 'Изменение версии',
        versionName: 'Наименование версии',
        notChosen: 'Не выбрано',

        subjectToApprovalEDO: 'На согласовании в ЭДО',
        approvedEDO: 'Согласован в ЭДО',
        status: 'Состояние',

        baseInfo: 'Основная информация графика',
        extraInfo: 'Дополнительная информация',
        introInfo: 'Вводная информация',
        chartInfo: 'Сведения о графике',
        objectGeneralPlan: 'Общий план объекта',
        versions: 'Версии графика',
        connectTelegram: 'Подключить график к Telegram',
        structureWillRewrite: 'Структура {0} версии будет перезаписана',
        structureWillRewriteDesc: 'Структура графика “{1}” будет заменена на структуру графика “{0}”.',
        structureWillRewriteDescCurr:
            ' Перед загрузкой рекомендуем создать резервную копию текущей версии, так как данные из неё будут удалены безвозвратно.',
        structureDidRewrite: 'Структура {0} версии успешно перезаписана',
        structureWillRewriteDescChosen: ' Предыдущие данные выбранной версии будут потеряны.',
        structureRewriteData: 'Данные взяты из {0}.',
        wantDelete: 'Вы действительно хотите безвозвратно удалить версию {0}?',
        didDelete: 'Версия {0} успешно удалена',
        canNotGoReport: 'Невозможно перейти в раздел',
        canNotGoReportDesc: 'Чтобы перейти в данный раздел, создайте структуру графика',
        fileUploading: 'Загрузка файла',
        uploadingError: 'Ошибка при загрузке',
        waitUploading: 'Подождите, файл загружается',
        fileIsUploaded: 'Файл загружен',
        addToReport: 'Добавить в отчет',

        stage: 'Стадия работ',
        calendar: 'Календарь графика',
        manager: 'Руководитель графика',
        designer: 'Куратор графика',
        periodNotChosen: 'Отчетный период не выбран',
        docNotChosen: 'Документ основание не выбран',
        successfullySaved: 'График успешно сохранен',

        bpc: {
            tab: 'БПГ',
            title: 'Базовый план-график',
        },

        dpcQuart: {
            tab: 'Квартальный ДПГ',
            title: 'Детальный план-график (квартальный ДПГ)',
            dateBeginLabel: 'Начало отчетного периода',
            dateEndLabel: 'Окончание отчетного периода',
        },

        dpcExec: {
            tab: 'Исполнение ДПГ',
            title: 'Детальный план-график (исполнение ДПГ)',
            datesLabel: 'Квартальный ДПГ (документ основание)',
        },

        weeklyReport: {
            tab: 'Недельный отчет',
            title: 'Отчет по строительству (Недельный)',
            constructionProgressTitle: 'Общие фото объекта на момент предоставления отчета по схеме:',
            countedTab: {
                introInfo: '1. Вводная информация',
                constructionProgress: '2. Ход строительства',
                jobTypes: '2.1 Виды работ',
            },
        },

        modal: {
            chooseDocument: 'Выберите Квартальный ДПГ (документ основания)',
            titleRewrite: 'Данные документа будут перезаполнены',
            titleSend: 'Отправить документ на согласование?',

            title: {
                structureRewriteSuccess: 'Структура текущей версии успешно перезаписана',
                structureWillRewrite: 'Структура текущей версии будет перезаписана',
            },

            description: {
                dataTakesFrom: 'Данные взяты из версии "{from}"',
                dataTakesFromCurrentVersion: 'Данные взяты из текущей версии',
                strucureReplaceFromTo: 'Структура графика "{to}" будет заменена на структуру графика "{from}".',
                recommendMakeVersionCopy:
                    'Перед загрузкой рекомедуем создать резервную копию текущей версии, так как данные из неё будут удалены безвозвратно.',
                previousDataWillLost: 'Предыдущие данные выбранной версии будут потеряны.',
            },

            acceptLabel: {
                allRight: 'Хорошо',
                upload: 'Загрузить',
                update: 'Обновить',
            },

            alert: {
                title: 'Нет графиков с указанными параметрами! Попробуйте изменить параметры фильтрации',
                actionLabel: 'Понятно',
            },
        },

        action: {
            cancelled: 'Аннулировать',
            complete: 'Завершить выполнение',
            resume: 'Возобновить выполнение',
            return: 'Вернуть в работу',
            stop: 'Остановить выполнение',
        },

        type: {
            title: 'Вид графика',
            consolidated: 'Сводный',
            design: 'Проектирование',
            construction: 'Строительство',
            tenders: 'Тендеры',
        },

        info: {
            cantRemoveBaseVersion: 'Вы не можете удалить базовую версию',
        },
    },

    document: {
        menu: 'Документы',
        title: 'Документы',
        bpc: { title: 'Базовый план-график' },
        dpcQuart: { title: 'Детальный план-график (квартальный ДПГ)' },
        dpcExec: { title: 'Детальный план-график (исполнение ДПГ)' },
        weeklyConstruction: { title: 'Отчет по строительству (недельный)' },
    },

    reports: {
        menu: 'Отчетность',
        title: 'Отчетность',
    },

    report: {
        title: 'Отчеты',
        dpcExec: {
            title: 'Отчет по исполнению ДПГ',
            periodFrom: 'Период от',
            periodUntil: 'Период до',
            deviationChart: 'График отклонения от буфера',
        },
        construction: {
            title: 'Отчет по строительству (месяц/год)',
            shortTitle: 'Отчет по строительству',
            realization: 'Отчет о ходе реализации строительства ЖК',
            objectStateStart: 'Объект на начало периода',
            objectStateFinish: 'Объект на конец периода',
            bufferDynamic: 'Динамика буфера критического пути',
        },
        consolidated: {
            title: 'Сводный отчет по графикам',
            shortTitle: 'Сводный отчет',
        },
        deadlines: {
            title: 'Сроки графика (план-факт)',
            shortTitle: 'Сроки графика',
        },
        bpc: {
            title: 'Базовый план-график',
            name: 'Название БПГ',
            base: 'Базовая',
        },
        dpcQuart: {
            title: 'Детальный план-график (квартальный ДПГ)',
            name: 'Название ДПГ',
        },
        weeklyReport: {
            title: 'Отчет по строительству (Недельный)',
            name: 'Недельный отчет',
        },
    },

    filter: {
        startFrom: 'Дата старта от',
        startTo: 'Дата старта до',
        beginFrom: 'Дата начала от',
        beginTo: 'Дата начала до',
        beginBPFrom: 'Дата начала по БП от',
        beginBPTo: 'Дата начала по БП до',
        endFrom: 'Дата завершения от',
        endTo: 'Дата завершения до',
        endBPFrom: 'Дата завершения по БП от',
        endBPTo: 'Дата завершения по БП до',
        durationFrom: 'Длительность от',
        durationTo: 'Длительность до',
        prognosisDurationFrom: 'Длительность (прогноз) от',
        prognosisDurationTo: 'Длительность (прогноз) до',
        readinessFrom: '% гот. от',
        readinessTo: '% гот. до',
        prognosisReadinessFrom: '% гот. (прогноз) от',
        prognosisReadinessTo: '% гот. (прогноз) до',
        deviationBeginFrom: 'Отклонение начала от',
        deviationBeginTo: 'Отклонение начала до',
        deviationEndFrom: 'Отклонение завершения от',
        deviationEndTo: 'Отклонение завершения до',
        showOnlyCurrent: 'Отображать только текущие задачи',
    },

    tab: {
        chartInfo: 'Информация о графике',
        structure: 'Структура',
        bpc: 'БПГ',
        dpcQuart: 'Квартальный ДПГ',
        dpcExec: 'Исполнение ДПГ',
        weeklyReport: 'Недельный отчет',
    },

    createEdit: {
        goStructure: 'Перейти к созданию структуры',
        goChart: 'Перейти к графику',
        successTitle: 'График успешно создан',
        leaveTitle: 'Вы действительно хотите выйти?',
        leaveDescription: 'Данные графика не будут сохранены',
    },

    action: {
        createDoc: 'Создать документ',
        uploadToExcel: 'Выгрузить в Excel',
        makeBase: 'Сделать базовой',
        sendForApproval: 'Отправить на согласование',
        formDoc: 'Сформировать',
        formDocFromChart: 'Заполнить по графику',
        filter: 'Отфильтровать',
    },

    role: {
        approvingUsers: 'Согласующие пользователи',
        conformingUsers: 'Утверждающие пользователи',
        mainEngineer: 'Главный инженер',
        branchDirector: 'Директор филиала',
        regionalHead: 'Региональный руководитель по строительству',
        constructionDirectorDeputy: 'Заместитель генерального директора по строительству',
        generalDirectorDeputy: 'Первый заместитель генерального директора',
    },

    placeholder: 'Вы еще не сформировали',

    column: {
        title: 'Название',
        codeSDR: 'Код СДР',
        beginning: 'Начало',
        ending: 'Завершение',
        reportPeriod: 'Отчетный период',
        reportPeriodBaseDoc: 'Отчетный период (документ основание)',
        id: 'ID',
        percentReadiness: 'Процент\nготовности\nпрогноз',
        completenessPlan: '%\n(план)',
        startDatePlan: 'Начало\n(план)',
        endDatePlan: 'Завершение\n(план)',
        completenessFact: '%\n(факт)',
        startDateFact: 'Начало\n(факт)',
        endDateFact: 'Завершение\n(факт)',
        completenessGeneral: 'Выполнение\nKi, %',
        N: '№',
        percentReadyPlan: '% гот.\n(план)',
        percentReadyFact: '% гот.\n(факт)',
        addedPhotos: 'Добавлено\nфотографий',
        jobsDesc: 'Описание работ',
        bufferDeviation: 'Отклонение от буфера',
        buffer: 'Буфер',
        duration: 'Длительность',
        beginBPAt: 'Начало по БП',
        endBPAt: 'Завершение\nпо БП',
        durationWD: 'Длительность\n(рабочие дни)',
        durationPrognosisWD: 'Длительность\n(рабочие дни,\nпрогноз)',
        percentReady: '% гот.',
        percentReadyPrognosis: '% гот.\n(прогноз)',
        executionDpc: 'Исполнение\nДПГ',
        deviationBegin: 'Отклонение\nначала',
        deviationEnd: 'Отклонение\nзавершения',
        cityProject: 'Город / Проект / Вид графика / Наименование графика',
        stage: 'Стадия работ',
        bpcName: 'Название БПГ',
        base: 'Базовая',
        acceptedDate: 'Дата согласования',
    },

    structure: {
        buttons: {
            cancelSelection: 'Отменить выбор',
            createTask: 'Создать задачу',
            basePlan: 'Базовый план-график',
            copy: 'Дублировать',
            moveUp: 'Переместить вверх',
            moveDown: 'Переместить вниз',
            riseLevel: 'Повысить уровень задачи',
            lowerLevel: 'Понизить уровень задачи',
            percentReady: '% готовности',
            table: 'Таблица',
            ganttChart: 'Диаграмма Ганта',
        },
        headers: {
            name: 'Название',
            code: 'Код',
            connections: 'Связи',
            chartsConnections: 'Связи м/у графиками',
            weight: 'Вес',
            ready: '% гот.',
            duration: 'Длительность',
            beginning: 'Текущее начало',
            ending: 'Текущее окончание',
            endingDeviation: 'Отклонение по окончанию ',
            beginningBP: 'Начало по БП',
            endingBP: 'Окончание по БП',
        },
        actions: {
            createTaskUpper: 'Создать задачу выше',
            createTaskLower: 'Создать задачу ниже',
            createTaskInner: 'Создать задачу внутри',
        },
    },

    chartInfo: {
        actions: {
            upload: 'Сделать версию текущей',
        },
    },
};
