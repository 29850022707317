import actions from './Actions';
import getters from './Getters';
import mutations from './Mutations';

// Interfaces
import { StoreOptions } from 'vuex';
import User from '@/ship/Models/User';
import Project from '@/ship/Models/Project';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Position from '@/ship/Models/Position';
import Organisation from '@/ship/Models/Organisation';
import Section from '@/ship/Models/Section';
import Floor from '@/ship/Models/Floor';
import Apartment from '@/ship/Models/Apartment';
import { TranslateResult } from 'vue-i18n';

export interface RootState {
    user: User | null;
    version: string | null;
    projects: Project[] | null;
    users: User[] | null;
    roles: Role[] | null;
    cities: City[] | null;
    positions: Position[] | null;
    organisations: Organisation[] | null;
    sections: Section[];
    floors: Floor[] | null;
    apartments: Apartment[] | null;

    appErrorMessage: string | null | TranslateResult;
    isLoadingApp: boolean;
}

export const state: RootState = {
    user: null,
    projects: null,
    users: null,
    roles: null,
    cities: null,
    positions: null,
    organisations: null,
    sections: [],
    floors: null,
    apartments: null,

    isLoadingApp: true,
    appErrorMessage: null,

    version: '1.0.0',
};

export const root: StoreOptions<RootState> = {
    state,
    actions,
    getters,
    mutations,
};
export default root;
