import { GetterTree } from 'vuex';
import { PrescriptionState } from '@/store/Prescription/PrescriptionRoot';

import naturalSort from 'javascript-natural-sort';

export const getters: GetterTree<PrescriptionState, PrescriptionState> = {
    sortedRequirements(state) {
        const requirements = state.requirements ? state.requirements.slice() : [];
        return requirements.sort((a, b) => naturalSort(a.name, b.name));
    },
};
export default getters;
