import Project from '@/ship/Models/Project';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import ProjectEditRequest from '@/ship/Requests/ProjectEditRequest';
import { AxiosResponse } from 'axios';

export const getProject = <D = Project>(id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<D>>(`/projects/${id}`, { params });

export const getAllProjects = <D = Project>(params: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/projects`, { params });

export const createProject = async (data: ProjectEditRequest): Promise<Project> => {
    const response = await Http.post<void, AxiosResponse<ITransformedValue<Project>>>(`/projects`, data);

    return response.data.data;
};

export const updateProject = async (id: number, data: ProjectEditRequest, include?: string): Promise<Project> => {
    const response = await Http.patch<void, AxiosResponse<ITransformedValue<Project>>>(
        `/projects/${id}`,
        { id, ...data },
        { params: { include } },
    );

    return response.data.data;
};

export const changeProjectImage = async (projectId: number, imageId: number) => {
    const response = await Http.get<void, AxiosResponse<ITransformedValue<{ fileUrl: string }>>>(
        `/projects/${projectId}/change/${imageId}`,
    );

    return response.data.data;
};

export const getProjectByGuid = <D = Project>(guid: string) =>
    Http.get<ITransformedValue<D>>(`/projects/guid/${guid}?include=generalContractor,customer`);

export const getProjectByCode = <D = Project>(code: string) =>
    Http.get<ITransformedValue<D>>(`/projects/code/${code}?include=generalContractor,customer`);
