import { GetRequestParams } from '@/ship/Requests/RequestParams';
import Http from '@/ship/Http';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import City from '@/ship/Models/City';
import CityEditRequest from '@/ship/Requests/CityEditRequest';
import { AxiosResponse } from 'axios';

export const getCity = (id: number, params?: GetRequestParams) =>
    Http.get<ITransformedValue<City>>(`/cities/${id}`, { params });

export const getAllCities = <D = City>(params?: GetRequestParams) =>
    Http.get<ITransformedValue<D[]>>(`/cities`, { params });

export const createCity = (data: CityEditRequest): Promise<AxiosResponse<ITransformedValue<City>>> => {
    return Http.post(`/cities`, data);
};

export const updateCity = (id: number, data: Partial<CityEditRequest>) =>
    Http.post<ITransformedValue<City>>(`/cities/${id}`, data);
